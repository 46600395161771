import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition, Teleport as _Teleport, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "relative"
};
const _hoisted_2 = {
  class: "flex flex-col gap-y-2"
};
const _hoisted_3 = {
  key: 0,
  class: "bg-neutral-50 dark:bg-neutral-950"
};
const _hoisted_4 = {
  class: "flex justify-between gap-2 md:justify-end"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_Undo2Icon = _resolveComponent("Undo2Icon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  return _withDirectives((_openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createElementVNode("div", {
    class: _normalizeClass([{
      'pointer-events-none': !$options.undos.length
    }, "fixed left-0 top-0 z-[999999] w-full px-1 md:bottom-10 md:left-10 md:top-auto md:w-80"])
  }, [_createElementVNode("ul", _hoisted_1, [_createVNode(_Transition, {
    "enter-active-class": "transition-all duration-300 ease-out",
    "enter-from-class": "opacity-0 translate-y-5",
    "enter-to-class": "opacity-100 translate-y-0",
    "leave-active-class": "transition-opacity duration-200 ease-out",
    "leave-to-class": "opacity-0",
    "leave-from-class": "opacity-100"
  }, {
    default: _withCtx(() => [$options.undos[0] ? (_openBlock(), _createElementBlock("li", {
      key: 0,
      class: _normalizeClass(_ctx.$style.undoContainer)
    }, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.undos[0].changes.slice(0, 3), (change, index) => {
      return _openBlock(), _createElementBlock("li", {
        key: index
      }, [_createVNode(_component_EntryDisplay, {
        modelValue: change.after,
        display: {
          custom_fields: false,
          settings: false
        },
        permissions: {
          name: false,
          status: false,
          links: false,
          description: false,
          schedule: false,
          output: false,
          input: false,
          senses: false,
          settings: false,
          routine: false,
          time_trackings: false,
          custom_fields: false
        },
        class: _normalizeClass(_ctx.$style.entryDisplay)
      }, null, 8, ["modelValue", "class"])]);
    }), 128)), $options.undos[0].changes.length > 3 ? (_openBlock(), _createElementBlock("li", _hoisted_3, " + " + _toDisplayString($options.undos[0].changes.length - 3) + " More ", 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_ButtonComponent, {
      onClick: _cache[0] || (_cache[0] = $event => [$options.undos[0].close()]),
      color: "secondary"
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode(" Close ")])),
      _: 1
    }), _createVNode(_component_ButtonComponentNew, {
      onClick: _cache[1] || (_cache[1] = $event => [$options.undos[0].undo()]),
      color: "primary",
      shortcode: "Backspace",
      shortcodeMeta: true,
      class: _normalizeClass(_ctx.$style.undoButton)
    }, {
      default: _withCtx(() => [_createVNode(_component_Undo2Icon, {
        size: "16"
      }), _cache[3] || (_cache[3] = _createTextVNode()), _cache[4] || (_cache[4] = _createElementVNode("span", null, " Undo ", -1))]),
      _: 1
    }, 8, ["class"])])], 2)) : _createCommentVNode("", true)]),
    _: 1
  })])], 2)], 512)), [[_vShow, $options.undos.length]]);
}