import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-5"
};
const _hoisted_2 = {
  key: 0,
  class: "flex justify-between pt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FilterIcon = _resolveComponent("FilterIcon");
  const _component_SectionHeader = _resolveComponent("SectionHeader");
  const _component_FilterBuilder = _resolveComponent("FilterBuilder");
  const _component_SaveIcon = _resolveComponent("SaveIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_SectionHeader, {
    url: "https://help.pensive.io/outputs"
  }, {
    title: _withCtx(() => [_createVNode(_component_FilterIcon, {
      size: "16"
    }), _cache[2] || (_cache[2] = _createTextVNode(" Output Filters "))]),
    _: 1
  }), _createVNode(_component_FilterBuilder, {
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.value = $event)
  }, null, 8, ["modelValue"]), $data.value.length == $options.validatedValue.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [JSON.stringify($data.value) != JSON.stringify($props.modelValue) ? (_openBlock(), _createBlock(_component_ButtonComponent, {
    key: 0,
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => _ctx.$emit('update:modelValue', $data.value), ["stop"])),
    color: "primary"
  }, {
    default: _withCtx(() => [_createVNode(_component_SaveIcon, {
      size: "20",
      "stroke-width": "1.25"
    }), _cache[3] || (_cache[3] = _createTextVNode(" Save "))]),
    _: 1
  })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]);
}