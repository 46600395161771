import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EditorContent = _resolveComponent("EditorContent");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      [_ctx.$style.nameDisplay]: true,
      [_ctx.$style.nameDisplay_disabled]: $props.disabled,
      name_display_color: $props.entry.color
    }, "name_display"]),
    onMousedown: _cache[0] || (_cache[0] = (...args) => $options.handleContainerClick && $options.handleContainerClick(...args)),
    onClick: _cache[1] || (_cache[1] = (...args) => $options.handleContainerClick && $options.handleContainerClick(...args))
  }, [_createVNode(_component_EditorContent, {
    editor: $data.editor,
    ref: "text_input",
    color: 'default',
    class: _normalizeClass({
      [`${_ctx.$style.editorContent}`]: true,
      [`${_ctx.$style.editorContent_editable}`]: !$props.disabled,
      [`${_ctx.$style.editorContent_disabled}`]: $props.disabled,
      [`${_ctx.$style.editorContent_temp}`]: $props.entry.temp
    })
  }, null, 8, ["editor", "class"])], 34);
}