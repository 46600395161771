import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DatePickerBuilder = _resolveComponent("DatePickerBuilder");
  const _component_TimePickerBuilder = _resolveComponent("TimePickerBuilder");
  return _openBlock(), _createElementBlock("section", null, [_createVNode(_component_DatePickerBuilder, {
    ref: "date",
    entry: $props.entry,
    modelValue: $data.date,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.date = $event),
    disabled: $props.disabled
  }, null, 8, ["entry", "modelValue", "disabled"]), _createVNode(_component_TimePickerBuilder, {
    ref: "time",
    entry: $props.entry,
    modelValue: $data.time,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.time = $event),
    disabled: $props.disabled
  }, null, 8, ["entry", "modelValue", "disabled"])]);
}