import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Transition as _Transition, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { Popover, PopoverButton, PopoverPanel, PopoverOverlay } from "@headlessui/vue";
import { ref } from "vue";
// eslint-disable-next-line no-unused-vars
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";
const __default__ = {
  provide() {
    return {
      position: "popover"
    };
  },
  mounted() {
    if (this.isOpen) {
      setTimeout(() => {
        this.$refs.reference.$el.click();
      }, 50);
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'PopoverHelper',
  props: {
    id: String,
    isOpen: Boolean,
    width: {
      type: String,
      default: "auto"
    },
    height: {
      type: String,
      default: "auto"
    },
    teleport: {
      type: Boolean,
      default: false
    },
    buttonClass: String,
    placement: {
      type: String,
      default: "bottom"
    },
    canOverflow: {
      type: Boolean,
      default: false
    }
  },
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const props = __props;
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      //strategy: "fixed",
      placement: props.placement,
      whileElementsMounted: autoUpdate,
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(Popover), null, {
        default: _withCtx(({
          open,
          close
        }) => [_createVNode(_unref(PopoverButton), {
          ref_key: "reference",
          ref: reference,
          class: _normalizeClass(["flex h-full w-full focus-visible:outline-none dark:text-neutral-500", __props.buttonClass ? __props.buttonClass : '']),
          "data-open": open,
          onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('open'))
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "button")]),
          _: 2
        }, 1032, ["class", "data-open"]), (_openBlock(), _createBlock(_Teleport, {
          disabled: !__props.teleport,
          to: "body"
        }, [_createVNode(_unref(PopoverOverlay), {
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop", "prevent"])),
          onPointerdown: _withModifiers($event => (close(), _ctx.$emit('close')), ["stop", "prevent"]),
          onTouchstart: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop", "prevent"])),
          class: "fixed inset-0 z-[250] bg-black opacity-0",
          id: (__props.id ? __props.id + '_' : '') + 'overlay'
        }, null, 8, ["onPointerdown", "id"]), _createVNode(_Transition, {
          "enter-active-class": "transition duration-200 ease-out",
          "enter-from-class": "translate-y-1 opacity-0",
          "enter-to-class": "translate-y-0 opacity-100",
          "leave-active-class": "transition duration-150 ease-in",
          "leave-from-class": "translate-y-0 opacity-100",
          "leave-to-class": "translate-y-1 opacity-0"
        }, {
          default: _withCtx(() => [_createVNode(_unref(PopoverPanel), {
            class: "fixed z-[250] mt-3 max-w-sm transform px-4 sm:px-0 lg:max-w-3xl"
          }, {
            default: _withCtx(({
              close
            }) => [_createElementVNode("div", {
              style: _normalizeStyle([_unref(floatingStyles), {
                "max-height": "calc(100vh - 20px)"
              }]),
              ref_key: "floating",
              ref: floating,
              class: _normalizeClass({
                [`${_ctx.$style.popoverPanel}`]: true,
                'overflow-y-auto': __props.canOverflow // note: this enables long filter lists to be scrollable and links builder in contextmenu
              })
            }, [_renderSlot(_ctx.$slots, "default", {
              close: close
            })], 6)]),
            _: 2
          }, 1024)]),
          _: 2
        }, 1024)], 8, ["disabled"]))]),
        _: 3
      });
    };
  }
});