import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  ref: "boundaries"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.visible ? _renderSlot(_ctx.$slots, "default", {
    key: 0
  }) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass([_ctx.$style.test])
  }, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.skeletonLoader)
  }, null, 2)], 2))], 512);
}