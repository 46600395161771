import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "relative grid w-screen max-w-sm gap-8 p-7 lg:grid-cols-1"
};
const _hoisted_2 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
};
const _hoisted_3 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
};
const _hoisted_4 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
};
const _hoisted_5 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
};
const _hoisted_6 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ListIcon = _resolveComponent("ListIcon");
  const _component_KanbanIcon = _resolveComponent("KanbanIcon");
  const _component_Grid2X2Icon = _resolveComponent("Grid2X2Icon");
  const _component_TableIcon = _resolveComponent("TableIcon");
  const _component_CalendarIcon = _resolveComponent("CalendarIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_PopoverButton = _resolveComponent("PopoverButton");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_PopoverHelper, null, {
    button: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
      class: _normalizeClass(_ctx.$style.settingsButton),
      title: "Change Output Type"
    }, {
      default: _withCtx(() => [_ctx.modelValue == 'list' ? (_openBlock(), _createBlock(_component_ListIcon, {
        key: 0,
        size: "16",
        strokeWidth: "1.5"
      })) : _createCommentVNode("", true), _ctx.modelValue == 'board' ? (_openBlock(), _createBlock(_component_KanbanIcon, {
        key: 1,
        size: "16",
        strokeWidth: "1.5"
      })) : _createCommentVNode("", true), _ctx.modelValue == 'gallery' ? (_openBlock(), _createBlock(_component_Grid2X2Icon, {
        key: 2,
        size: "16",
        strokeWidth: "1.5"
      })) : _createCommentVNode("", true), _ctx.modelValue == 'table' ? (_openBlock(), _createBlock(_component_TableIcon, {
        key: 3,
        size: "16",
        strokeWidth: "1.5"
      })) : _createCommentVNode("", true), ['calendar', 'calendar_day', 'calendar_week', 'calendar_month'].includes(_ctx.modelValue) ? (_openBlock(), _createBlock(_component_CalendarIcon, {
        key: 4,
        size: "16",
        strokeWidth: "1.5"
      })) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["class"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', 'list')),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_ListIcon, {
        size: "20"
      })]), _cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
      }, " List "), _createElementVNode("p", {
        class: "text-sm text-gray-500 dark:text-neutral-400"
      }, " Render entries as a list ")], -1))]),
      _: 1
    }), _createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('update:modelValue', 'board')),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_KanbanIcon, {
        size: "20"
      })]), _cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
      }, " Board "), _createElementVNode("p", {
        class: "text-sm text-gray-500 dark:text-neutral-400"
      }, " Render entries as a kanban board ")], -1))]),
      _: 1
    }), _createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('update:modelValue', 'gallery')),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_Grid2X2Icon, {
        size: "20"
      })]), _cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
      }, " Gallery "), _createElementVNode("p", {
        class: "text-sm text-gray-500 dark:text-neutral-400"
      }, " Render entries as a gallery ")], -1))]),
      _: 1
    }), _createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[3] || (_cache[3] = $event => _ctx.$emit('update:modelValue', 'table')),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_TableIcon, {
        size: "20"
      })]), _cache[8] || (_cache[8] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
      }, " Table "), _createElementVNode("p", {
        class: "text-sm text-gray-500 dark:text-neutral-400"
      }, " Render entries as a table ")], -1))]),
      _: 1
    }), _createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[4] || (_cache[4] = $event => _ctx.$emit('update:modelValue', 'calendar')),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_CalendarIcon, {
        size: "20"
      })]), _cache[9] || (_cache[9] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
      }, " Calendar "), _createElementVNode("p", {
        class: "text-sm text-gray-500 dark:text-neutral-400"
      }, " Render entries as a calendar ")], -1))]),
      _: 1
    })])]),
    _: 1
  })]);
}