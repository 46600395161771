import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "flex flex-col"
};
const _hoisted_3 = {
  class: "flex justify-center"
};
const _hoisted_4 = {
  class: "text-xs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  return $options.entries.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("section", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_ButtonComponentNew, {
    onClick: _cache[0] || (_cache[0] = $event => $data.showOutput = !$data.showOutput),
    class: _normalizeClass(_ctx.$style.toggleShowLinkedButton)
  }, {
    default: _withCtx(() => [_createElementVNode("span", {
      class: _normalizeClass([{
        'rotate-90': $data.showOutput
      }, "transition-transform duration-200 ease-in-out"])
    }, [_createVNode(_component_ChevronRightIcon, {
      size: "12",
      "stroke-width": "1.5"
    })], 2), _createElementVNode("span", _hoisted_4, _toDisplayString($options.entries?.length) + " Filtered Linked Entries ", 1)]),
    _: 1
  }, 8, ["class"])]), $data.showOutput ? (_openBlock(), _createBlock(_component_OutputDisplay, {
    key: 0,
    modelValue: $options.defaultOutput,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.defaultOutput = $event),
    ref: "output",
    entry: $options.entry,
    filter: $data.filter,
    sorting: $options.entry.output?.sorting,
    editable: false,
    showInput: false,
    position: $props.position,
    class: "mt-5"
  }, null, 8, ["modelValue", "entry", "filter", "sorting", "position"])) : _createCommentVNode("", true)])])) : _createCommentVNode("", true);
}