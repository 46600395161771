import { withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["value"];
const _hoisted_2 = ["onClick"];
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";
const __default__ = {
  inheritAttrs: false,
  props: {
    modelValue: String
  },
  watch: {
    time() {
      this.setInternalTimeAsLocal();
    },
    showPopover(val) {
      if (val) {
        setTimeout(() => {
          const utcTime = this.$moment.utc(this.$moment().format("YYYY-MM-DD") + " " + (this.time ? this.time : this.$moment().format("HH:mm:ss")));
          const scrollToTime = utcTime.minute(Math.floor(utcTime.minute() / 15) * 15).format("HH:mm");
          if (this.$refs && this.$refs[scrollToTime]) this.$refs[scrollToTime][0].scrollIntoView({
            behavior: "instant",
            block: "start"
          });
        }, 0);
      }
    }
  },
  mounted() {
    this.setInternalTimeAsLocal();
  },
  computed: {
    time: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    times() {
      return Array.from({
        length: 24
      }, (_, hour) => {
        return ["00", "15", "30", "45"].map(minute => {
          return `${String(hour).padStart(2, "0")}:${minute}`;
        });
      }).flat();
    }
  },
  methods: {
    toUTC(time) {
      if (!this.isValidTime(time)) {
        return null;
      }
      return this.$moment(this.$moment.utc().format("YYYY-MM-DD") + " " + time).utc().format("HH:mm:ss");
    },
    setInternalTimeAsLocal() {
      if (this.time === null) {
        this.internalTimeAsLocal = "";
        return;
      }
      this.internalTimeAsLocal = this.$moment.utc(this.$moment.utc().format("YYYY-MM-DD") + " " + this.time).local().format("HH:mm");
    },
    isValidTime(time) {
      return /^([01]\d|2[0-3])(:[0-5]\d(:[0-5]\d)?)?$/.test(time);
    },
    handleOnFocus() {
      this.showPopover = true;
      this.$refs.reference.select();
    },
    handleOnBlur() {
      this.delayShowPopover(false);
      if (this.internalTimeAsLocal === "") {
        this.time = null;
        return;
      }
      if (!this.isValidTime(this.internalTimeAsLocal)) {
        this.setInternalTimeAsLocal();
        return;
      }
      if (this.time != this.toUTC(this.internalTimeAsLocal)) {
        this.time = this.toUTC(this.internalTimeAsLocal);
        this.setInternalTimeAsLocal();
      }
    },
    delayShowPopover(show) {
      setTimeout(() => {
        this.showPopover = show;
      }, 100);
    }
  },
  data() {
    return {
      internalTimeAsLocal: "",
      showPopover: false
    };
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'TimePicker',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "bottom-start",
      whileElementsMounted: autoUpdate,
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("input", _mergeProps({
        ref_key: "reference",
        ref: reference,
        value: _ctx.internalTimeAsLocal,
        onInput: _cache[0] || (_cache[0] = $event => _ctx.internalTimeAsLocal = $event.target.value),
        type: "text",
        onFocus: _cache[1] || (_cache[1] = (...args) => _ctx.handleOnFocus && _ctx.handleOnFocus(...args)),
        onBlur: _cache[2] || (_cache[2] = (...args) => _ctx.handleOnBlur && _ctx.handleOnBlur(...args)),
        onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers($event => _ctx.$refs.reference.blur(), ["stop", "prevent"]), ["enter"]))
      }, _ctx.$attrs), null, 16, _hoisted_1), _ctx.showPopover ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "floating",
        ref: floating,
        class: _normalizeClass(_ctx.$style.popover),
        style: _normalizeStyle(_unref(floatingStyles))
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.times, (_time, key) => {
        return _openBlock(), _createElementBlock("div", {
          key: key,
          ref_for: true,
          ref: _time,
          onClick: _withModifiers($event => _ctx.time = _ctx.toUTC(_time), ["prevent"])
        }, [_renderSlot(_ctx.$slots, "timeSuggestion", {
          timeLocal: _time
        }, () => [_createElementVNode("button", {
          class: _normalizeClass(_ctx.$style.buttonTimeSuggestion)
        }, _toDisplayString(_time), 3)])], 8, _hoisted_2);
      }), 128))], 6)) : _createCommentVNode("", true)], 64);
    };
  }
});