import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex items-center gap-x-4 gap-y-4"
};
const _hoisted_2 = {
  class: "flex items-center gap-x-4"
};
const _hoisted_3 = {
  class: "hidden text-xs font-bold uppercase md:inline-block"
};
const _hoisted_4 = {
  class: "flex flex-1 items-center justify-center gap-4"
};
const _hoisted_5 = {
  class: "md:flex md:flex-1 md:justify-end"
};
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
import { addonBlueprint } from "@/addonBlueprint";
import { Routining } from "../mixins/Routining";
const __default__ = {
  mixins: [Statusing, Scheduling, Routining],
  props: {
    size: {
      type: String,
      default: "md"
    }
  },
  data() {
    return {
      navigator: navigator,
      bulkStatus: null,
      bulkModel: {
        status_id: null,
        links: [],
        schedule: {
          date: "",
          time: null
        }
      }
    };
  },
  computed: {
    entry() {
      if (this.$route.params.id2) {
        return this.entries.find(e => e.id == this.$route.params.id2);
      }
      return this.entries.find(e => e.id == this.$route.params.id);
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    selectedEntries() {
      return this.$store.getters.selected.map(id => this.entries.find(entry => entry.id == id)).filter(e => e !== undefined);
    }
  },
  methods: {
    updateDate(val) {
      this.$store.getters.selected.map(id => this.$store.getters.entries.find(entry => entry.id == id)).forEach(e => {
        if (!e.schedule) {
          e.schedule = addonBlueprint("schedule", e);
        }
        this.setEntrySchedule(e, {
          date: this.interpretDate({
            date: val
          }),
          time: e.schedule?.time
        });
        !e.temp ? this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: e
          },
          entry: e
        }) : null;
      });
      this.$store.dispatch("selected", null);
    },
    // updateTime(val) {
    //   if (this.entry.schedule === null) return;

    //   if (
    //     (val === null || (val && val.op && val.op === "null")) &&
    //     this.entry.schedule?.date === null
    //   ) {
    //     this.deleteAddon();
    //     return;
    //   }

    //   // TODO need to move this part over to Addon Mixin updateAddon()
    //   this.setEntrySchedule(this.entry, {
    //     date: this.entry.schedule?.date,
    //     time: this.interpretTime({ time: val }),
    //   });

    //   if (
    //     this.entry.schedule.time === null &&
    //     this.entry.schedule.date === null
    //   ) {
    //     this.deleteAddon();
    //   } else {
    //     !this.entry.temp
    //       ? this.$store.dispatch("push", {
    //           event: "entry_update",
    //           params: { entry: this.entry },
    //           entry: this.entry,
    //         })
    //       : null;
    //   }
    // },

    deleteEntry(entry = null) {
      if (entry === null) entry = this.entry;
      entry.deleted_at = this.$moment.utc().format("YYYY-MM-DD HH:mm:ss");
      this.$store.getters.entries.filter(e => e.links.includes(entry.id)).forEach(e => {
        e.links.splice(e.links.findIndex(l => l == entry.id), 1);
      });
      if (!entry.temp) this.$store.dispatch("push", {
        event: "entry_delete",
        params: {
          id: entry.id
        },
        entry: entry
      });
      if (this.$route.params.id == entry.id) this.$router.back();
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'BulkSelectMenu',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_XIcon = _resolveComponent("XIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_CircleDashedIcon = _resolveComponent("CircleDashedIcon");
      const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
      const _component_StatusSettings = _resolveComponent("StatusSettings");
      const _component_CalendarIcon = _resolveComponent("CalendarIcon");
      const _component_DatePickerBuilder = _resolveComponent("DatePickerBuilder");
      const _component_Link2Icon = _resolveComponent("Link2Icon");
      const _component_LinksBuilder = _resolveComponent("LinksBuilder");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_ButtonComponent, {
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$store.dispatch('selected', null)),
        variant: "round",
        color: "primary"
      }, {
        default: _withCtx(() => [_createVNode(_component_XIcon, {
          size: "20"
        })]),
        _: 1
      }), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$store.getters.selected.length) + " entries selected ", 1)]), _cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "hidden sm:block sm:h-6 sm:w-px sm:bg-gray-200 dark:sm:bg-gray-800",
        "aria-hidden": "true"
      }, null, -1)), _createElementVNode("div", _hoisted_4, [_createVNode(_component_StatusSettings, {
        modelValue: _ctx.bulkStatus,
        "onUpdate:modelValue": [_cache[1] || (_cache[1] = $event => _ctx.bulkStatus = $event), _cache[2] || (_cache[2] = status => {
          _ctx.selectedEntries.forEach(entry => {
            _ctx.setStatus(entry, status);
            _ctx.$store.dispatch('push', {
              event: 'entry_update',
              params: {
                entry: entry
              },
              entry: entry
            });
          }), _ctx.$store.dispatch('selected', null), _ctx.bulkStatus = null;
        })],
        full: true,
        statuses: _ctx.$merge(_ctx.getOwnAvailableStatuses(_ctx.entry), JSON.stringify(_ctx.getOwnAvailableStatuses(_ctx.entry)) != JSON.stringify(_ctx.getAvailableStatuses(_ctx.entry)) ? _ctx.getAvailableStatuses(_ctx.entry) : []),
        size: __props.size,
        color: "secondary"
      }, {
        button: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
          title: "Apply Status",
          class: _normalizeClass(_ctx.$style.buttonRound)
        }, {
          default: _withCtx(() => [_createVNode(_component_CircleDashedIcon, {
            size: "20"
          })]),
          _: 1
        }, 8, ["class"])]),
        _: 1
      }, 8, ["modelValue", "statuses", "size"]), _createVNode(_component_DatePickerBuilder, {
        ref: "date",
        showRecurrence: false,
        autoSetOnClick: false,
        "onUpdate:modelValue": _ctx.updateDate,
        teleport: true
      }, {
        button: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
          title: "Apply Schedule Date",
          class: _normalizeClass(_ctx.$style.buttonRound)
        }, {
          default: _withCtx(() => [_createVNode(_component_CalendarIcon, {
            size: "20"
          })]),
          _: 1
        }, 8, ["class"])]),
        _: 1
      }, 8, ["onUpdate:modelValue"]), _createVNode(_component_LinksBuilder, {
        modelValue: _ctx.bulkModel.links,
        "onUpdate:modelValue": [_cache[3] || (_cache[3] = $event => _ctx.bulkModel.links = $event), _cache[4] || (_cache[4] = links => {
          _ctx.selectedEntries.forEach(e => {
            e.links = [...e.links, ...links.map(link => ({
              id: link,
              position: link.length,
              settings: {}
            }))];
            _ctx.$store.dispatch('push', {
              event: 'entry_update',
              params: {
                entry: e
              },
              entry: e
            });
          });
          _ctx.$store.dispatch('selected', null);
          _ctx.bulkModel.links = [];
        })]
      }, {
        button: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
          title: "Apply Links",
          class: _normalizeClass(_ctx.$style.buttonRound)
        }, {
          default: _withCtx(() => [_createVNode(_component_Link2Icon, {
            size: "20"
          })]),
          _: 1
        }, 8, ["class"])]),
        _: 1
      }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_ButtonComponentNew, {
        onClick: _cache[5] || (_cache[5] = $event => (_ctx.selectedEntries.forEach(e => _ctx.deleteEntry(e)), _ctx.$store.dispatch('selected', null))),
        title: "Delete selected entries",
        class: _normalizeClass(_ctx.$style.buttonRound),
        style: {
          "--ps-button-color": "var(--ps-base-background-color)",
          "--ps-button-color-hover": "var(--ps-base-tinted-color)",
          "--ps-button-bg-color": "var(--ps-base-delete-color)",
          "--ps-button-bg-color-hover": "var(--ps-base-delete-color)"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_TrashIcon, {
          class: "h-5",
          "aria-hidden": "true"
        })]),
        _: 1
      }, 8, ["class"])])])]);
    };
  }
});