import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  ref: "content",
  class: "container relative mx-auto h-0 overflow-visible"
};
const _hoisted_2 = {
  ref: "header"
};
const _hoisted_3 = {
  class: "mx-auto w-full flex-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_IconHelper = _resolveComponent("IconHelper");
  const _component_LeftSidebar = _resolveComponent("LeftSidebar");
  const _component_RightSidebar = _resolveComponent("RightSidebar");
  const _component_ApplicationHeader = _resolveComponent("ApplicationHeader");
  const _component_DotIcon = _resolveComponent("DotIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_HeartIcon = _resolveComponent("HeartIcon");
  const _component_ApplicationFooter = _resolveComponent("ApplicationFooter");
  const _component_CommandPalette = _resolveComponent("CommandPalette");
  const _component_DraggableWrapper = _resolveComponent("DraggableWrapper");
  const _component_Toasts = _resolveComponent("Toasts");
  const _component_Undos = _resolveComponent("Undos");
  return _openBlock(), _createElementBlock("div", {
    ref: "shell",
    class: _normalizeClass([{
      'bg-neutral-100 dark:bg-neutral-900': !$options.space,
      'bg-white dark:bg-black': $options.space
    }, "max-w-full dark:text-white"]),
    style: {
      "transition": "background-color 1s ease-out"
    }
  }, [_createVNode(_component_IconHelper, {
    name: "star",
    size: "24",
    class: "hidden"
  }), (_openBlock(), _createBlock(_Teleport, {
    to: "body"
  })), (_openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createVNode(_Transition, {
    "enter-active-class": "transition-opacity duration-300 ease-linear",
    "enter-from-class": "opacity-0",
    "enter-to-class": "opacity-100",
    "leave-active-class": "transition-opacity duration-200 ease-linear",
    "leave-from-class": "opacity-100",
    "leave-to-class": "opacity-0"
  }, {
    default: _withCtx(() => [$data.leftSidebarOpen || $data.rightSidebarOpen ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      onClick: _cache[0] || (_cache[0] = $event => ($data.leftSidebarOpen = false, $data.rightSidebarOpen = false, $data.cursorIsOnLeftEdge = false, $data.cursorIsOnRightEdge = false)),
      class: "fixed inset-0 backdrop-blur md:hidden"
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_Transition, {
    "enter-from-class": $data.leftSidebarOpen ? 'opacity-0 -translate-x-72 !delay-[400ms]' : 'opacity-100 translate-x-0',
    "enter-to-class": "opacity-100 translate-x-0",
    "leave-to-class": "opacity-0 -translate-x-72",
    "leave-from-class": "opacity-100 translate-x-0",
    appear: ""
  }, {
    default: _withCtx(() => [$options.showSidebar ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      ref: "left-sidebar-wrapper",
      class: _normalizeClass([{
        '-ml-72': !$data.leftSidebarOpen && !$data.cursorIsOnLeftEdge
      }, "fixed inset-y-0 z-20 flex w-72 max-w-[80%] flex-col"]),
      style: {
        "transition": "transform 800ms cubic-bezier(0.47, 1.64, 0.41, 1),\n              opacity 800ms cubic-bezier(0.47, 1.64, 0.41, 1),\n              margin-left 400ms"
      }
    }, [_createVNode(_component_LeftSidebar)], 2)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["enter-from-class"])])), (_openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createVNode(_Transition, {
    "enter-active-class": "transition-opacity duration-300 ease-linear",
    "enter-from-class": "opacity-0",
    "enter-to-class": "opacity-100",
    "leave-active-class": "transition-opacity duration-200 ease-linear",
    "leave-from-class": "opacity-100",
    "leave-to-class": "opacity-0"
  }, {
    default: _withCtx(() => [$data.leftSidebarOpen || $data.rightSidebarOpen ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      onClick: _cache[1] || (_cache[1] = $event => ($data.leftSidebarOpen = false, $data.rightSidebarOpen = false, $data.cursorIsOnLeftEdge = false, $data.cursorIsOnRightEdge = false)),
      class: "fixed inset-0 backdrop-blur md:hidden"
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_Transition, {
    "enter-from-class": $data.rightSidebarOpen ? 'opacity-0 translate-x-72 !delay-[400ms]' : 'opacity-100 translate-x-0',
    "enter-to-class": "opacity-100 translate-x-0",
    "leave-to-class": "opacity-0 translate-x-72",
    "leave-from-class": "opacity-100 translate-x-0",
    appear: ""
  }, {
    default: _withCtx(() => [$options.showSidebar ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      ref: "right-sidebar-wrapper",
      class: _normalizeClass([{
        '-mr-72': !$data.rightSidebarOpen && !$data.cursorIsOnRightEdge
      }, "fixed inset-y-0 right-0 z-20 flex w-72 max-w-[80%] flex-col"]),
      style: {
        "transition": "transform 800ms cubic-bezier(0.47, 1.64, 0.41, 1),\n              opacity 800ms cubic-bezier(0.47, 1.64, 0.41, 1),\n              margin-right 400ms"
      }
    }, [_createVNode(_component_RightSidebar, {
      modelValue: $data.rightSidebarOpen,
      "onUpdate:modelValue": [_cache[2] || (_cache[2] = $event => $data.rightSidebarOpen = $event), _cache[3] || (_cache[3] = value => {
        $data.rightSidebarOpen = value;
        $options.user.settings = {
          ...$options.user.settings,
          display: {
            ...$options.user.settings?.display,
            left_sidebar: $data.leftSidebarOpen,
            right_sidebar: value
          }
        };
        _ctx.$store.dispatch('push', {
          event: 'user_update',
          params: {
            user: $options.user
          },
          user: $options.user
        });
      })]
    }, null, 8, ["modelValue"])], 2)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["enter-from-class"])])), _createElementVNode("div", {
    class: _normalizeClass([{
      'md:ml-72': $options.showSidebar && $data.leftSidebarOpen,
      'md:mr-72': $options.showSidebar && $data.rightSidebarOpen
    }, "h-screen max-w-full overflow-clip"]),
    style: {
      "transition": "margin 0.7s",
      "position": "relative",
      "z-index": "10"
    }
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_Transition, {
    appear: "",
    "enter-active-class": "transition-all duration-[800ms] ease-spring delay-[1200ms]",
    "enter-from-class": "opacity-0 -translate-y-24",
    "enter-to-class": "opacity-100 translate-y-0"
  }, {
    default: _withCtx(() => [$options.space && !_ctx.$route.meta.hideHeader ? (_openBlock(), _createBlock(_component_ApplicationHeader, {
      key: 0,
      sidebarDesktopOpen: $data.leftSidebarOpen,
      scrollY: $data.scrollY,
      onToggleSidebar: $options.toggleSidebar,
      onToggleSidebarMobile: _cache[4] || (_cache[4] = $event => $data.leftSidebarOpen = !$data.leftSidebarOpen),
      onToggleCommandPalette: _cache[5] || (_cache[5] = $event => $data.showCommandPalette = !$data.showCommandPalette),
      onMounted: $options.initResizeObserverHeader,
      class: _normalizeClass(_ctx.$style.stickyRow)
    }, null, 8, ["sidebarDesktopOpen", "scrollY", "onToggleSidebar", "onMounted", "class"])) : _createCommentVNode("", true)]),
    _: 1
  })], 512)], 512), _createElementVNode("main", {
    ref: "main",
    id: "main",
    class: _normalizeClass([[$options.focus ? 'overflow-y-hidden' : 'overflow-y-auto', {
      [_ctx.$style.mainContent]: $data.rightSidebarOpen
    }], "relative flex h-full flex-col gap-2 overflow-x-hidden"]),
    style: {
      "transition": "padding-top 1s ease,\n            padding-bottom 1s ease",
      "-webkit-transform": "translateZ(0)",
      "scrollbar-gutter": "stable",
      "z-index": "5"
    }
  }, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default")]), $options.space ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.postContent)
  }, [_createVNode(_component_DotIcon, {
    size: "24",
    strokeWidth: "1.5",
    class: "block"
  }), _createVNode(_component_DotIcon, {
    size: "20",
    strokeWidth: "1.5",
    class: "inline"
  }), _createVNode(_component_DotIcon, {
    size: "16",
    strokeWidth: "1.5",
    class: "inline"
  }), _ctx.$store.getters.version && $options.parseVersion(_ctx.$version) < $options.parseVersion(_ctx.$store.getters.version) ? (_openBlock(), _createBlock(_component_ButtonComponentNew, {
    key: 0,
    onClick: _cache[6] || (_cache[6] = $event => _ctx.$window.location.reload()),
    class: _normalizeClass(["text-red-500", _ctx.$style.version])
  }, {
    default: _withCtx(() => [_createTextVNode(" v." + _toDisplayString(_ctx.$store.getters.version) + " available ", 1)]),
    _: 1
  }, 8, ["class"])) : _ctx.$store.getters.version && $options.parseVersion(_ctx.$version) > $options.parseVersion(_ctx.$store.getters.version) ? (_openBlock(), _createBlock(_component_ButtonComponentNew, {
    key: 1,
    onClick: _cache[7] || (_cache[7] = $event => _ctx.$window.location.reload()),
    class: _normalizeClass(["text-blue-500", _ctx.$style.version])
  }, {
    default: _withCtx(() => [_createTextVNode(" v." + _toDisplayString(_ctx.$store.getters.version) + " / v." + _toDisplayString(_ctx.$version), 1)]),
    _: 1
  }, 8, ["class"])) : (_openBlock(), _createElementBlock("a", {
    key: 2,
    href: "https://pensive.io/whats-new",
    target: "_blank",
    class: _normalizeClass(_ctx.$style.version)
  }, " v." + _toDisplayString(_ctx.$version), 3)), _createElementVNode("span", {
    class: _normalizeClass(_ctx.$style.madeWith)
  }, [_cache[11] || (_cache[11] = _createTextVNode(" Made with ")), _createVNode(_component_HeartIcon, {
    size: "14",
    strokeWidth: "1.5",
    class: "inline-block"
  })], 2)], 2)) : _createCommentVNode("", true)], 2), _createVNode(_Transition, {
    appear: "",
    "enter-active-class": "transition-all duration-[800ms] ease-spring delay-[1200ms]",
    "enter-from-class": "opacity-0 translate-y-24",
    "enter-to-class": "opacity-100 translate-y-0",
    "leave-active-class": "transition-all duration-[800ms] ease-spring",
    "leave-from-class": "opacity-100 translate-y-0",
    "leave-to-class": "opacity-0 translate-y-24"
  }, {
    default: _withCtx(() => [$options.space && $options.showFooter ? (_openBlock(), _createBlock(_component_ApplicationFooter, {
      key: 0,
      ref: "footer",
      entry: $options.entry,
      scrollY: $data.scrollY,
      onToggleSidebarMobile: _cache[8] || (_cache[8] = $event => $data.leftSidebarOpen = !$data.leftSidebarOpen),
      onToggleCommandPalette: _cache[9] || (_cache[9] = $event => $data.showCommandPalette = !$data.showCommandPalette),
      onMounted: $options.initResizeObserverFooter,
      class: _normalizeClass([_ctx.$style.stickyRow, "container mx-auto"])
    }, null, 8, ["entry", "scrollY", "onMounted", "class"])) : _createCommentVNode("", true)]),
    _: 1
  })], 2), _createVNode(_component_CommandPalette, {
    isOpen: $data.showCommandPalette,
    onOnClose: _cache[10] || (_cache[10] = $event => $data.showCommandPalette = false)
  }, null, 8, ["isOpen"]), _createVNode(_component_DraggableWrapper), _createVNode(_component_Toasts), _createVNode(_component_Undos)], 2);
}