import { Applicators } from "../mixins/Applicators";
import { DragAndDrop } from "../mixins/DragAndDrop";
import { Filtering } from "../mixins/Filtering";
import { Grouping } from "../mixins/Grouping";
import { Routining } from "../mixins/Routining";
import { Scheduling } from "../mixins/Scheduling";
import { Schema } from "../mixins/Schema";
import { Sorting } from "../mixins/Sorting";
import { Statusing } from "../mixins/Statusing";
import { Routing } from "../mixins/Routing";
// import { Lazying } from "../mixins/Lazying";
// import { Viewporting } from "../mixins/Viewporting";

import EntryDisplayDetail from "@/components/entry/partials/EntryDisplayDetail.vue";
import EntryDisplayList from "@/components/entry/partials/EntryDisplayList.vue";
import EntryDisplayTable from "@/components/entry/partials/EntryDisplayTable.vue";
export default {
  inject: {
    overrideEntries: {
      default: null
    },
    output_entry_origin: {
      default: null
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    EntryDisplayDetail,
    EntryDisplayList,
    EntryDisplayTable
  },
  mixins: [Schema, Filtering, Grouping, Sorting, Statusing, DragAndDrop, Scheduling, Routining, Applicators, Routing
  // Lazying,
  // Viewporting,
  ],
  props: {
    modelValue: Object,
    output: Object,
    placeholder: String,
    position: String,
    display: Object,
    disabled: Boolean,
    permissions: {
      type: Object,
      default: () => ({
        name: true,
        description: true,
        status: true,
        schedule: true,
        routine: true,
        senses: true
      })
    },
    renderType: {
      type: String,
      default: "list"
    },
    disableRouting: Boolean,
    containingOutputGroup: String,
    size: {
      type: String,
      default: "md"
    },
    toggleRenderOutput: Function,
    isRenderOutput: Boolean,
    entryIndex: Number
  },
  data() {
    return {
      isInitialized: true,
      isVisibleInViewport: false,
      dataDisplay: {},
      defaultDisplay: {
        name: true,
        status: true,
        procrastination: true,
        description: true,
        time_trackings: true,
        priority: true,
        routine: true,
        senses: true,
        schedule: true,
        output: true,
        input: true,
        links: true,
        columns: true,
        leftover: true,
        settings: true,
        custom_fields: true
      },
      navigator: navigator,
      showOutput: false,
      showConfig: false,
      configX: null,
      configY: null,
      // entries: [],
      // children: [],

      availableStatuses: [],
      customFields: [],
      renderOutput: this.isRenderOutput,
      isSelected: false,
      isDragged: false,
      // $store.getters.dragged.findIndex((e) => e.id == entry.id) === -1
      isLazyMounted: false
    };
  },
  mounted() {
    // console.log("create->mounted: " + this.modelValue.name);
    this.dataDisplay = this.display;
    this.availableStatuses = this.availableStatusesComputed;
    this.customFields = this.customFieldsComputed;
    // setTimeout(() => {
    this.pullData();
    // }, 1000);
    // this.$onIdle(() => {
    requestAnimationFrame(() => {
      this.isLazyMounted = true;
    });
  },
  // eslint-disable-next-line no-unused-vars
  // renderTracked(event) {
  //   console.trace();
  //   console.log("renderTracked e", this.entry.name, this.position, event);
  // },
  // // eslint-disable-next-line no-unused-vars
  // renderTriggered(event) {
  //   // console.trace();
  //   console.log("renderTriggered e", this.entry.name, this.position, event);
  // },
  computed: {
    renderComponent() {
      switch (this.renderType) {
        case "detail":
          return "EntryDisplayDetail";
        case "table":
          return "EntryDisplayTable";
        case "list":
        default:
          return "EntryDisplayList";
      }
    },
    computedDisplay() {
      return {
        ...this.defaultDisplay,
        ...this.dataDisplay
      };
    },
    entry: {
      get() {
        return this.modelValue;
      },
      set() {
        //this.$emit("update:modelValue", value);
      }
    },
    space() {
      return this.$store.getters.space;
    },
    // entries() {
    //   return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    // },
    // children() {
    //   return this.filterEntries(this.outputSchema(this.entry))
    //     .filter(this.filterGroups(this.entry.output?.grouping))
    //     .sort(this.sortEntries(this.entry.output?.sorting));
    // },
    status: {
      get() {
        return this.getStatusById(this.entry.status_id);
      },
      set(status) {
        this.setStatus(this.entry, status);
        if (!this.entry.temp) this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    },
    customFieldsComputed() {
      return this.entry.links?.map(link => this.$store.getters.entries.find(e => e.id == link.id)).filter(e => e?.custom_fields?.length).flatMap(e => e.custom_fields);
    },
    availableStatusesComputed() {
      if (this.overrideStatuses) return this.overrideStatuses;
      return this.$merge(this.getAvailableStatuses(this.entry), this.output_entry_origin && JSON.stringify(this.getAvailableStatuses(this.entry)) != JSON.stringify(this.getAvailableStatuses(this.output_entry_origin)) ? this.getAvailableStatuses(this.output_entry_origin) : []);
    },
    isActive() {
      return this.entry.id == this.$route.params.id;
    },
    cssProps() {
      return {
        "--ps-entry-display-color": this.entry.color ? "var(--ps-color-" + this.entry.color + "-500)" : null
      };
    }
    // isNameDisabled() {
    //   return (
    //     this.disabled ||
    //     this.position == "calendar" ||
    //     (this.renderType == "table" && !this.entry.temp) ||
    //     (this.renderType == "board" && !this.entry.temp) ||
    //     this.position == "template" ||
    //     (!this.entry.temp &&
    //       ((navigator.userAgent.includes("Mobile") &&
    //         this.$route.params.id != this.entry.id &&
    //         this.$route.params.id2 != this.entry.id) ||
    //         !["center", "bound", "modal"].includes(this.position)))
    //   );
    // },
  },
  methods: {
    pullData() {
      if (this.entry.output) {
        // this.$nextTick(() => {
        this.$store.dispatch("pull", {
          filters: this.entry.output.filters,
          source: {
            file: "EntryDisplay",
            entry: this.entry.name
          }
        });
        // });
      }
    },
    onClick(e) {
      if (process.env.NODE_ENV === "development" && e.altKey) {
        this.isLazyMounted = !this.isLazyMounted;
        return;
      }
      if (this.disableClick) return;
      if (!this.entry.temp) {
        if (this.$route.params.id != this.entry.id && this.$route.params.id2 != this.entry.id && (e.shiftKey || e.ctrlKey || navigator.userAgent.includes("Mobile") && this.$store.getters.selected.length > 0)) {
          // Bulk Selection
          this.$store.dispatch("selected", this.entry);
          return;
        }
        if (e.altKey) {
          // Open in new tab
          window.open(window.location.origin + "/" + this.entry.id, "_blank");
          return;
        }
        if (!this.disableRouting && !window.getSelection().toString().length) {
          this.navigateTo(this.entry);
        }
      }
    },
    contextMenu(e, settingsClicked = false) {
      if (this.entry.temp) {
        return;
      }
      if (this.$route.params.id == this.entry.id && this.position == "center") {
        return;
      }

      /**
       * pointerType is undefined in cypress e2e tests
       */
      if (e.pointerType == "mouse" || typeof e.pointerType == "undefined") {
        if (e.type == "contextmenu" || settingsClicked) {
          /**
           * stopPropagation is needed to
           * prevent the contextmenu event
           * from potential parent entry
           */
          e.stopPropagation();
          e.preventDefault();
          if (this.computedDisplay.settings) {
            this.mousePositionOnDragStart.x = null;
            this.mousePositionOnDragStart.y = null;
            this.showConfig = !this.showConfig;
          }
        }
      } else {
        // no contextmenu on touch devices
        // if (this.showConfigOnPress == this.showConfig)
        //   this.showConfig = !this.showConfig;
        // e.stopPropagation();
      }
    },
    updateEntry() {
      this.$store.dispatch("push", {
        event: "entry_update",
        params: {
          entry: this.entry
        },
        entry: this.entry
      });
    },
    animationEnterContextmenu(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [20, 0],
        complete: done
      });
    }
  },
  watch: {
    display: {
      handler: function (n, o) {
        if (JSON.stringify(n) != JSON.stringify(o)) {
          this.dataDisplay = n;
        }
      },
      immediate: true
    },
    "$store.getters.selected": function (n) {
      const isSelected = n.findIndex(id => id == this.entry.id) !== -1;
      if (isSelected != this.isSelected) this.isSelected = isSelected;
    },
    "$store.getters.dragged": function (n) {
      const isDragged = n.findIndex(e => e.id == this.entry.id) !== -1;
      if (isDragged != this.isDragged) this.isDragged = isDragged;
    },
    availableStatusesComputed: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) this.availableStatuses = n;
    },
    customFieldsComputed: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) this.customFields = n;
    },
    "$store.getters.timestamp": function (n, o) {
      if (n - o > 2) {
        this.$onIdle(() => {
          this.pullData();
        });
      }
    },
    showConfig() {
      if (this.showConfig) {
        this.configX = this.$cursorPosition.x;
        this.configY = this.$cursorPosition.y;
        this.$nextTick(() => {
          /**
           * This is used to prevent the config
           * form from overlapping on the right side
           */
          if (window.innerWidth < this.configX + this.$refs.configForm.offsetWidth) {
            this.configX = window.innerWidth - this.$refs.configForm.offsetWidth - 20;
          }
          /**
           * This is used to prevent the config
           * form from overlapping on the bottom side
           */

          if (window.innerHeight < this.configY + this.$refs.configForm.offsetHeight) {
            this.configY = window.innerHeight - this.$refs.configForm.offsetHeight - window.innerHeight / 10;
          }
          this.configX = Math.max(5, this.configX);
          this.configY = Math.max(5, this.configY);
        });
      }
    }
  }
};