import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "flex-grow lg:flex lg:flex-col lg:justify-between lg:gap-4"
};
const _hoisted_2 = {
  class: "flex items-start gap-5"
};
const _hoisted_3 = {
  key: 0,
  class: "lg:col-span-3 lg:pl-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_GripVerticalIcon = _resolveComponent("GripVerticalIcon");
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  const _component_InputDisplay = _resolveComponent("InputDisplay");
  const _component_OutputCount = _resolveComponent("OutputCount");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      [_ctx.$style.linkedEntry]: true,
      'flex-col lg:grid lg:grid-cols-4 lg:divide-x dark:divide-neutral-700': !$props.settings?.collapsed,
      'items-start': $props.settings?.collapsed
    }, "flex gap-4"])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.linkedEntrySettings, "flex items-center gap-2"])
  }, [$props.linkedEntries.length > 1 ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "data-handle": "",
    class: _normalizeClass([_ctx.$style.linkedEntryHandle, _ctx.$style.interactableElements])
  }, [_createVNode(_component_GripVerticalIcon, {
    size: "16"
  })], 2)) : _createCommentVNode("", true), _createVNode(_component_ButtonComponentNew, {
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.$emit('toggleCollapse'), ["stop"])),
    title: "Toggle collapse",
    class: _normalizeClass([_ctx.$style.interactableElements, 'text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-300'])
  }, {
    default: _withCtx(() => [!$props.settings?.collapsed ? (_openBlock(), _createBlock(_component_ChevronDownIcon, {
      key: 0,
      size: "16"
    })) : (_openBlock(), _createBlock(_component_ChevronRightIcon, {
      key: 1,
      size: "16"
    }))]),
    _: 1
  }, 8, ["class"])], 2), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.linkedEntryWrapper)
  }, [_createVNode(_component_EntryDisplay, {
    modelValue: $props.entry,
    permissions: {
      name: false,
      description: false,
      status: false,
      priority: false,
      time_trackings: false
    },
    display: {
      links: false,
      output: false,
      routine: false,
      custom_fields: false
    },
    class: _normalizeClass(_ctx.$style.linkedEntryDisplay)
  }, {
    contextmenu: _withCtx(() => [_renderSlot(_ctx.$slots, "contextmenu")]),
    _: 3
  }, 8, ["modelValue", "class"])], 2)])]), !$props.settings?.collapsed ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$props.entry.output ? (_openBlock(), _createBlock(_component_OutputDisplay, {
    key: 0,
    modelValue: $props.entry.output,
    display: {
      links: $props.entry.output.type != 'gallery',
      output: $props.entry.output.type != 'gallery'
    },
    entry: $props.entry,
    filter: $props.outputFilter,
    inputs: $props.linkedEntries,
    editable: true,
    sorting: $props.entry.output.sorting,
    position: $props.position,
    class: _normalizeClass(_ctx.$style.linkedOutputDisplay)
  }, null, 8, ["modelValue", "display", "entry", "filter", "inputs", "sorting", "position", "class"])) : _createCommentVNode("", true), !$props.entry.output && $props.entry.input ? (_openBlock(), _createBlock(_component_InputDisplay, {
    key: 1,
    modelValue: $props.entry.input,
    entry: $props.entry,
    schema: $props.entry.input.schema,
    position: $props.position
  }, null, 8, ["modelValue", "entry", "schema", "position"])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("a", {
    key: 1,
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('toggleCollapse')),
    class: _normalizeClass([_ctx.$style.outputCount, _ctx.$style.interactableElements])
  }, [_createVNode(_component_OutputCount, {
    entry: $props.entry,
    customFilter: e => e.id != $props.parent.id
  }, null, 8, ["entry", "customFilter"]), _cache[2] || (_cache[2] = _createTextVNode(" Entries "))], 2))], 2);
}