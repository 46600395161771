import "core-js/modules/es.array.push.js";
import { Schema } from "../mixins/Schema";
import { Filtering } from "../mixins/Filtering";
import { Grouping } from "../mixins/Grouping";
import { Sorting } from "../mixins/Sorting";
import { addonBlueprint } from "@/addonBlueprint";
export default {
  mixins: [Schema, Filtering, Sorting, Grouping],
  props: {
    outputId: {
      type: String,
      default: () => {
        return "navigation";
      }
    }
  },
  inject: {
    output_entry_permissions: {
      default: {
        name: false
      }
    }
  },
  data() {
    return {
      originsEntry: {
        temp: true,
        name: "Add Entry",
        output: {
          type: "list",
          grouping: [],
          filters: [{
            key: ["anchors", "placement"],
            op: "eq",
            val: "left-sidebar"
          }],
          sorting: [{
            field: ["anchor", "position"],
            dir: 1,
            placement: "left-sidebar"
          }]
        },
        input: {
          schema: {
            ...{
              anchors: [{
                placement: "left-sidebar",
                position: {
                  op: "append"
                }
              }]
            },
            ...(!this.$Cypress // @note only when not testing?
            ? {
              output: {},
              input: {}
            } : {})
          }
        },
        links: []
      },
      links: [
        // Removed the version link
        // {
        //   url: "https://forms.gle/B5hi53AM9PZsR4jz5",
        //   text: "Report a Bug",
        //   classes: "",
        // },
        // {
        //   url: "https://forms.gle/PQXmMKPwGQQhAin7A",
        //   text: "Request a Feature",
        //   classes: "",
        // },
      ]
    };
  },
  computed: {
    space() {
      return this.$store.getters.space;
    },
    entries() {
      console.log("leftsidebar:entries updated");
      return this.$store.getters.entries;
    },
    anchoredEntries() {
      return this.filterEntries(this.originsEntry.output.filters).sort(this.sortEntries(this.originsEntry.output.sorting));
      // .filter(this.filter)
      // .sort(this.sortEntries(this.sorting))
      // .sort((a, b) => {
      //   if (this.output.custom_sorting) {
      //     const indexA = this.output.custom_sorting.indexOf(a.id);
      //     if (indexA == -1) return 1;
      //     // console.log("A", a.name, indexA);

      //     const indexB = this.output.custom_sorting.indexOf(b.id);
      //     if (indexB == -1) return -1;
      //     // console.log("B", b.name, indexB);
      //     return indexA - indexB;
      //   }
      //   return 0;
      // });
    },
    cssProps() {
      return {
        "--ps-output-display-entry-display-background-color-hover": this.$store.getters.dragged.length ? "transparent" : null
      };
    }
  },
  methods: {
    handleAnchorSorting(schema) {
      let dragged = this.$store.getters.dragged;
      let newIndex = schema.dropIndex;
      let anchor_sorting = this.anchoredEntries.map(e => e.id);
      dragged.forEach(entry => {
        const oldIndex = anchor_sorting.indexOf(entry.id);
        // console.log("oldIndex", oldIndex);
        if (oldIndex > -1) {
          anchor_sorting.splice(oldIndex, 1);
          if (newIndex > oldIndex) {
            newIndex = newIndex - 1;
          }
        }
        // console.log("newIndex", newIndex);
        anchor_sorting.splice(newIndex, 0, entry.id);

        // custom_sorting_filtered.splice(index, 0, entry.id);
      });
      setTimeout(() => {
        // this.$nextTick(() => {
        let position = 0;
        anchor_sorting.forEach(id => {
          let index = this.$store.getters.entries.findIndex(e => e.id == id);
          let entry = this.$store.getters.entries.find(e => e.id == id);
          let existingLeftSidebarAnchor = entry.anchors?.find(a => a.placement === "left-sidebar");

          // Find the left sidebar anchor or create a new one
          let leftSidebarAnchor = existingLeftSidebarAnchor || {
            ...addonBlueprint("anchor", entry, this),
            ...{
              placement: "left-sidebar"
            }
          };
          leftSidebarAnchor = {
            ...leftSidebarAnchor,
            position
          };

          // Update or add the left sidebar anchor
          const anchors = entry.anchors || [];
          const anchorIndex = anchors.findIndex(a => a.placement === "left-sidebar");
          if (anchorIndex >= 0) {
            anchors[anchorIndex] = leftSidebarAnchor;
          } else {
            anchors.push(leftSidebarAnchor);
          }
          entry = {
            ...entry,
            anchors
          };
          this.$store.getters.entries[index] = entry;
          this.$store.dispatch("push", {
            event: existingLeftSidebarAnchor ? "anchor_update" : "anchor_create",
            params: {
              anchor: leftSidebarAnchor
            },
            entry: entry,
            undo: true
          });
          position++;
        });
        // });
      }, 0);
    }
  }
};