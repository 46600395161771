import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  id: "application-header",
  class: "pointer-events-none flex items-center justify-between"
};
const _hoisted_2 = {
  key: 1,
  class: "max-w-fit"
};
const _hoisted_3 = {
  key: 0,
  class: "hidden items-center gap-x-4 p-2 sm:flex"
};
const _hoisted_4 = {
  class: "flex gap-x-2"
};
const _hoisted_5 = {
  key: 0,
  class: "group/back relative"
};
const _hoisted_6 = {
  class: "pointer-events-none absolute -bottom-2 left-0 z-10 w-max translate-y-full truncate rounded-md bg-white px-2 py-1 text-sm opacity-0 drop-shadow-md group-hover/back:block group-hover/back:opacity-100 group-hover/back:transition-opacity group-hover/back:delay-300"
};
const _hoisted_7 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(['pointer-events-none flex-none text-xs font-semibold text-neutral-500'])
};
const _hoisted_8 = {
  class: "font-sans"
};
const _hoisted_9 = {
  class: "flex justify-center gap-x-2 self-stretch md:gap-x-4 lg:gap-x-6"
};
const _hoisted_10 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(['pointer-events-none absolute right-4 flex-none text-xs font-semibold text-neutral-500'])
};
const _hoisted_11 = {
  class: "font-sans"
};
const _hoisted_12 = {
  key: 0,
  class: "hidden sm:block sm:h-6 sm:w-px sm:bg-gray-200 dark:sm:bg-gray-800",
  "aria-hidden": "true"
};
const _hoisted_13 = {
  class: "flex items-center gap-x-4 p-2"
};
const _hoisted_14 = {
  key: 1,
  class: "hidden sm:block sm:h-6 sm:w-px sm:bg-gray-200 dark:sm:bg-gray-800",
  "aria-hidden": "true"
};
const _hoisted_15 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BulkSelectMenu = _resolveComponent("BulkSelectMenu");
  const _component_PanelLeftIcon = _resolveComponent("PanelLeftIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_PanelLeftCloseIcon = _resolveComponent("PanelLeftCloseIcon");
  const _component_PanelLeftOpenIcon = _resolveComponent("PanelLeftOpenIcon");
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon");
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_SearchIcon = _resolveComponent("SearchIcon");
  const _component_TourHelper = _resolveComponent("TourHelper");
  const _component_TransitionGroupHelper = _resolveComponent("TransitionGroupHelper");
  const _component_HeaderTimeTrackingComponent = _resolveComponent("HeaderTimeTrackingComponent");
  const _component_UserIcon = _resolveComponent("UserIcon");
  const _component_MenuButton = _resolveComponent("MenuButton");
  const _component_MenuItem = _resolveComponent("MenuItem");
  const _component_MenuItems = _resolveComponent("MenuItems");
  const _component_Menu = _resolveComponent("Menu");
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass([{
      'shadow-lg ring-1 ring-inset ring-neutral-100 dark:shadow-none dark:ring-neutral-700': $props.scrollY > 15
    }, "pointer-events-auto -translate-x-2 rounded-full bg-white/75 backdrop-blur transition-all dark:bg-black/75"])
  }, [_createVNode(_component_TransitionGroupHelper, {
    enter: {
      translateX: [20, 0]
    }
  }, {
    default: _withCtx(() => [!_ctx.$route.params.id2 && _ctx.$store.getters.selected.length ? (_openBlock(), _createBlock(_component_BulkSelectMenu, {
      key: 0,
      class: "min-h-14 px-2 py-2.5"
    })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_Transition, {
      onEnter: $options.animateEnter,
      onLeave: $options.animateLeave
    }, {
      default: _withCtx(() => [_ctx.$route.meta.showSidebar !== false ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_ButtonComponent, {
        ref: "left_sidebar_toggle_mobile",
        id: "left_sidebar_toggle_mobile",
        variant: "round",
        color: "neutral",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('toggleSidebarMobile')),
        class: _normalizeClass([{
          // '-m-2 rounded-lg p-2 ring-2 ring-blue-400':
          //   $store.getters.dragged?.length,
        }, "hidden !bg-neutral-50 !text-neutral-800 hover:!bg-neutral-100 hover:!text-black sm:block md:hidden dark:!bg-neutral-950 dark:!text-neutral-200 dark:hover:!bg-neutral-900 dark:hover:!text-neutral-100"]),
        onMouseenter: _cache[1] || (_cache[1] = e => {
          if (_ctx.$store.getters.dragged?.length) {
            _ctx.$emit('toggleSidebarMobile');
            // $refs.left_sidebar_toggle_mobile.click();
          }
        }),
        title: "Toggle sidebar",
        shortcode: "m"
      }, {
        default: _withCtx(() => [_cache[9] || (_cache[9] = _createElementVNode("span", {
          class: "sr-only"
        }, "Open sidebar", -1)), _createVNode(_component_PanelLeftIcon, {
          "stroke-width": "1.25",
          "aria-hidden": "true"
        })]),
        _: 1
      }, 512), _createVNode(_component_ButtonComponent, {
        variant: "round",
        color: "secondary",
        size: "lg",
        class: "hidden md:block",
        onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('toggleSidebar', true)),
        onMouseenter: _cache[3] || (_cache[3] = e => {
          if (_ctx.$store.getters.dragged?.length) {
            _ctx.$emit('toggleSidebar', false);
            // sidebarDesktopOpen = true;
          }
        }),
        title: "Toggle sidebar",
        shortcode: "m"
      }, {
        default: _withCtx(() => [_cache[10] || (_cache[10] = _createElementVNode("span", {
          class: "sr-only"
        }, "Open sidebar", -1)), $props.sidebarDesktopOpen ? (_openBlock(), _createBlock(_component_PanelLeftCloseIcon, {
          key: 0,
          size: "24"
        })) : (_openBlock(), _createBlock(_component_PanelLeftOpenIcon, {
          key: 1,
          size: "24"
        }))]),
        _: 1
      }), 3 > 4 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_ButtonComponent, {
        variant: "round",
        class: "dark:!text-neutral-20 dark:hover:!text-neutral-1000 !bg-neutral-50 !text-neutral-800 hover:!bg-neutral-100 hover:!text-black dark:!bg-neutral-950 dark:!text-neutral-200 dark:hover:!bg-neutral-900 dark:hover:!text-neutral-100",
        onClick: _cache[4] || (_cache[4] = $event =>
        // go back in history
        _ctx.$window.history.back())
      }, {
        default: _withCtx(() => [_cache[11] || (_cache[11] = _createElementVNode("span", {
          class: "sr-only"
        }, "Go back", -1)), _createVNode(_component_ChevronLeftIcon, {
          class: "m-0.5 h-4 w-4",
          "aria-hidden": "true"
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_6, [_cache[13] || (_cache[13] = _createTextVNode(" Go back ")), !$data.navigator.userAgent.includes('Mobile') ? (_openBlock(), _createElementBlock("span", _hoisted_7, [_createElementVNode("kbd", _hoisted_8, _toDisplayString(_ctx.$metaKey), 1), _cache[12] || (_cache[12] = _createElementVNode("kbd", {
        class: "font-sans"
      }, "[", -1))])) : _createCommentVNode("", true)])])) : _createCommentVNode("", true), 3 > 4 ? (_openBlock(), _createElementBlock("button", {
        key: 1,
        type: "button",
        class: "-m-2.5 hidden p-2.5 text-gray-700 lg:block",
        onClick: _cache[5] || (_cache[5] = $event =>
        // go forward
        _ctx.$window.history.forward()),
        style: _normalizeStyle({
          display: _ctx.$window.history && _ctx.$window.history.state ? 'none' : null //$window.history.length <= 1,
        })
      }, [_cache[14] || (_cache[14] = _createElementVNode("span", {
        class: "sr-only"
      }, "Go forward", -1)), _createVNode(_component_ChevronRightIcon, {
        class: "h-4 w-4",
        "aria-hidden": "true"
      })], 4)) : _createCommentVNode("", true)]), _cache[17] || (_cache[17] = _createElementVNode("div", {
        class: "hidden sm:block sm:h-6 sm:w-px sm:bg-gray-200 dark:sm:bg-gray-800",
        "aria-hidden": "true"
      }, null, -1)), _createElementVNode("div", _hoisted_9, [_createVNode(_component_ButtonComponent, {
        onClick: _cache[6] || (_cache[6] = $event => _ctx.$emit('toggleCommandPalette')),
        color: "white",
        size: "lg",
        class: "relative hidden w-[200px] flex-1 items-center self-center !rounded-full bg-white ring-1 ring-inset ring-neutral-200 sm:flex dark:ring-neutral-700"
      }, {
        default: _withCtx(() => [_createVNode(_component_SearchIcon, {
          size: "24",
          "stroke-width": "1.75",
          "aria-hidden": "true"
        }), _cache[16] || (_cache[16] = _createTextVNode(" Search ")), !$data.navigator.userAgent.includes('Mobile') ? (_openBlock(), _createElementBlock("span", _hoisted_10, [_createElementVNode("kbd", _hoisted_11, _toDisplayString(_ctx.$metaKey), 1), _cache[15] || (_cache[15] = _createElementVNode("kbd", {
          class: "font-sans"
        }, "K", -1))])) : _createCommentVNode("", true)]),
        _: 1
      })]), _ctx.$store.getters.tours?.find(t => t.hook == 'application_header') ? (_openBlock(), _createElementBlock("div", _hoisted_12)) : _createCommentVNode("", true), _createVNode(_component_TourHelper, {
        hook: "application_header"
      })])) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["onEnter", "onLeave"])]))]),
    _: 1
  })], 2), _createElementVNode("div", {
    class: _normalizeClass([{
      'shadow-lg ring-1 ring-inset ring-neutral-100 dark:ring-neutral-700': $props.scrollY > 15
    }, "pointer-events-auto max-w-fit translate-x-2 rounded-full bg-white/75 backdrop-blur transition-all dark:bg-black/75"])
  }, [_createElementVNode("div", _hoisted_13, [$options.isShowTimeTrackings ? (_openBlock(), _createBlock(_component_HeaderTimeTrackingComponent, {
    key: 0
  })) : _createCommentVNode("", true), $options.isShowTimeTrackings ? (_openBlock(), _createElementBlock("div", _hoisted_14)) : _createCommentVNode("", true), _createVNode(_component_Menu, {
    as: "div",
    class: "relative"
  }, {
    default: _withCtx(() => [_createVNode(_component_MenuButton, {
      class: "focus-visible:outline-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_ButtonComponent, {
        color: "secondary",
        variant: "round",
        size: "lg",
        title: "Open user menu"
      }, {
        default: _withCtx(() => [_cache[18] || (_cache[18] = _createElementVNode("span", {
          class: "sr-only"
        }, "Open user menu", -1)), _createVNode(_component_UserIcon, {
          size: "24"
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_Transition, {
      "enter-active-class": "transition ease-out duration-100",
      "enter-from-class": "transform opacity-0 scale-95",
      "enter-to-class": "transform opacity-100 scale-100",
      "leave-active-class": "transition ease-in duration-75",
      "leave-from-class": "transform opacity-100 scale-100",
      "leave-to-class": "transform opacity-0 scale-95"
    }, {
      default: _withCtx(() => [_createVNode(_component_MenuItems, {
        class: "absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none dark:bg-black dark:ring-neutral-100/10"
      }, {
        default: _withCtx(() => [_ctx.$route.meta.showSidebar ? (_openBlock(), _createBlock(_component_MenuItem, {
          key: 0
        }, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            onClick: _cache[7] || (_cache[7] = $event => _ctx.$router.push({
              name: 'profile'
            })),
            class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
          }, " Profile ", 2)]),
          _: 1
        })) : _createCommentVNode("", true), _ctx.$route.meta.showSidebar && _ctx.$store.getters.entries.filter(e => e.routine).length ? (_openBlock(), _createBlock(_component_MenuItem, {
          key: 1
        }, {
          default: _withCtx(({
            active
          }) => [_createElementVNode("a", {
            onClick: _cache[8] || (_cache[8] = $event => _ctx.$router.push({
              name: 'routines'
            })),
            class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
          }, " Routines ", 2)]),
          _: 1
        })) : _createCommentVNode("", true), _ctx.$route.meta.showSidebar ? (_openBlock(), _createBlock(_component_Menu, {
          key: 2,
          as: "div",
          class: "relative w-full"
        }, {
          default: _withCtx(() => [_createVNode(_component_MenuButton, {
            as: "button",
            class: "w-full focus-visible:outline-0"
          }, {
            default: _withCtx(() => [_createVNode(_component_MenuItem, null, {
              default: _withCtx(({
                active
              }) => [_createElementVNode("div", {
                class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 dark:text-neutral-300'])
              }, " Help ", 2)]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_Transition, {
            "enter-active-class": "transition ease-out duration-100",
            "enter-from-class": "transform opacity-0 scale-95",
            "enter-to-class": "transform opacity-100 scale-100",
            "leave-active-class": "transition ease-in duration-75",
            "leave-from-class": "transform opacity-100 scale-100",
            "leave-to-class": "transform opacity-0 scale-95"
          }, {
            default: _withCtx(() => [_createVNode(_component_MenuItems, {
              class: "absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none dark:bg-black dark:ring-neutral-100/10"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.helpItems, (item, index) => {
                return _openBlock(), _createBlock(_component_MenuItem, {
                  key: index,
                  as: "div"
                }, {
                  default: _withCtx(({
                    active
                  }) => [_createElementVNode("a", {
                    href: item.url,
                    target: "_blank",
                    class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
                  }, _toDisplayString(item.title), 11, _hoisted_15)]),
                  _: 2
                }, 1024);
              }), 128))]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_MenuItem, null, {
          default: _withCtx(({
            active
          }) => [$options.token ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: {
              name: 'logout'
            },
            class: _normalizeClass([active ? 'bg-gray-50 dark:bg-neutral-900' : '', 'block w-full px-3 py-1 text-sm leading-6 text-gray-900 dark:text-neutral-300'])
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [_createTextVNode(" Logout ")])),
            _: 2
          }, 1032, ["class"])) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })])], 2)]);
}