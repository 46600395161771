import { withModifiers as _withModifiers, withKeys as _withKeys, mergeProps as _mergeProps, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["value"];
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "ml-auto text-neutral-500"
};
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";
const __default__ = {
  inheritAttrs: false,
  props: {
    modelValue: Number,
    basis: String
  },
  data() {
    return {
      internalTime: "",
      internalVisibleTime: "",
      showPopover: false
    };
  },
  watch: {
    duration(val) {
      this.internalDuration = val;
      this.calculateTime();
    },
    // eslint-disable-next-line no-unused-vars
    basis(val) {
      this.calculateTime();
    }
  },
  mounted() {
    this.internalDuration = this.time;
    this.calculateTime();
  },
  computed: {
    duration: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    times() {
      return Array.from({
        length: 24
      }, (_, hour) => {
        return ["00", "15", "30", "45"].map(minute => {
          return `${String(hour).padStart(2, "0")}:${minute}`;
        });
      }).flat();
    }
  },
  methods: {
    renderDuration(time) {
      const timeMoment = this.$moment.utc(this.$moment().format("YYYY-MM-DD") + " " + time);
      return this.$moment.utc(this.$moment(timeMoment).diff(this.$moment.utc(this.basis))).format("H[h] mm[m]", {
        stopTrim: "m"
      });
    },
    isValidTime(time) {
      return /^([01]\d|2[0-3])(:[0-5]\d(:[0-5]\d)?)?$/.test(time);
    },
    calculateTime() {
      if (this.basis === null) {
        this.internalVisibleTime = "";
        return;
      }
      this.internalVisibleTime = this.$moment.utc(this.basis).add(this.duration ? this.duration : 0, "seconds").local().format("HH:mm");
    },
    handleOnFocus() {
      this.showPopover = true;
      this.$refs.reference.select();
    },
    handleOnBlur() {
      this.delayShowPopover(false);
      if (this.internalVisibleTime === "") {
        this.duration = null;
        return;
      }
      if (!this.isValidTime(this.internalVisibleTime)) {
        this.calculateTime();
        return;
      }
      if (this.isValidTime(this.internalVisibleTime)) {
        const endTimeMoment = this.$moment(this.$moment.utc(this.basis).format("YYYY-MM-DD") + " " + this.internalVisibleTime);
        if (endTimeMoment.isValid()) {
          this.$refs.reference.blur();
          this.duration = endTimeMoment.utc().diff(this.$moment.utc(this.basis), "seconds");
        }
      }
    },
    // handleOnEnter() {
    //   if (this.isValidTime(this.internalVisibleTime)) {
    //     const endTimeMoment = this.$moment(
    //       this.$moment.utc(this.basis).format("YYYY-MM-DD") +
    //         " " +
    //         this.internalVisibleTime,
    //     );
    //     if (endTimeMoment.isValid()) {
    //       this.$refs.reference.blur();
    //       this.duration = endTimeMoment
    //         .utc()
    //         .diff(this.$moment.utc(this.basis), "seconds");
    //     }
    //   }
    // },
    delayShowPopover(show) {
      setTimeout(() => {
        this.showPopover = show;
      }, 100);
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'DurationPicker',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "bottom-start",
      whileElementsMounted: autoUpdate,
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("input", _mergeProps({
        ref_key: "reference",
        ref: reference,
        value: _ctx.internalVisibleTime,
        onInput: _cache[0] || (_cache[0] = $event => _ctx.internalVisibleTime = $event.target.value),
        type: "text",
        onFocus: _cache[1] || (_cache[1] = (...args) => _ctx.handleOnFocus && _ctx.handleOnFocus(...args)),
        onBlur: _cache[2] || (_cache[2] = (...args) => _ctx.handleOnBlur && _ctx.handleOnBlur(...args)),
        onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers($event => _ctx.$refs.reference.blur(), ["stop", "prevent"]), ["enter"])),
        class: _ctx.$style.input
      }, _ctx.$attrs), null, 16, _hoisted_1), _ctx.showPopover ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "floating",
        ref: floating,
        class: _normalizeClass(_ctx.$style.popover),
        style: _normalizeStyle(_unref(floatingStyles))
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.times, (time, key) => {
        return _openBlock(), _createElementBlock("div", {
          key: key,
          onClick: _withModifiers(() => {
            _ctx.internalVisibleTime = _ctx.$moment.utc(_ctx.$moment.utc(__props.basis).format('YYYY-MM-DD') + ' ' + time).local().format('HH:mm');
            _ctx.duration = _ctx.$moment.utc(_ctx.$moment.utc(__props.basis).format('YYYY-MM-DD') + ' ' + time).diff(_ctx.$moment.utc(__props.basis), 'seconds');
          }, ["prevent"])
        }, [_renderSlot(_ctx.$slots, "timeSuggestion", {
          time: time
        }, () => [_withDirectives(_createElementVNode("button", {
          class: _normalizeClass(_ctx.$style.buttonTimeSuggestion)
        }, [_createElementVNode("span", null, _toDisplayString(_ctx.$moment.utc(_ctx.$moment().format("YYYY-MM-DD") + " " + time).local().format("HH:mm")), 1), _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.renderDuration(time)), 1)], 2), [[_vShow, _ctx.$moment.utc(_ctx.$moment.utc(__props.basis).format('YYYY-MM-DD') + ' ' + time).isAfter(__props.basis ? _ctx.$moment.utc(__props.basis) : _ctx.$moment.utc())]])])], 8, _hoisted_2);
      }), 128))], 6)) : _createCommentVNode("", true)], 64);
    };
  }
});