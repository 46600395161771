import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-y-1"
};
const _hoisted_2 = {
  class: "flex flex-wrap items-start gap-2"
};
const _hoisted_3 = {
  "data-handle": "",
  class: "flex cursor-move gap-1 self-center"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
  const _component_ColorPicker = _resolveComponent("ColorPicker");
  const _component_TextInput = _resolveComponent("TextInput");
  const _component_SettingsIcon = _resolveComponent("SettingsIcon");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_draggable = _resolveComponent("draggable");
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_SenseBuilder = _resolveComponent("SenseBuilder");
  const _component_ModalHelper = _resolveComponent("ModalHelper");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.senses.length ? (_openBlock(), _createBlock(_component_draggable, {
    key: 0,
    class: "list-group",
    modelValue: $options.senses,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.senses = $event),
    group: "senses",
    handle: "[data-handle]",
    onChange: $options.updatePositions,
    itemKey: "name"
  }, {
    item: _withCtx(({
      element
    }) => [_createElementVNode("div", _hoisted_2, [_createElementVNode("a", _hoisted_3, [_createVNode(_component_ChevronsUpDownIcon, {
      size: "20",
      class: "text-gray-900"
    })]), _createVNode(_component_ColorPicker, {
      modelValue: element.color,
      "onUpdate:modelValue": $event => element.color = $event,
      onUpdate: color => {
        element.color = color;
        $options.updateSense(element);
      },
      hideLabel: true,
      class: "flex-1"
    }, null, 8, ["modelValue", "onUpdate:modelValue", "onUpdate"]), _createVNode(_component_TextInput, {
      modelValue: element.name,
      "onUpdate:modelValue": $event => element.name = $event,
      onUpdate: $event => $options.updateSense(element),
      class: "flex-1 rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    }, null, 8, ["modelValue", "onUpdate:modelValue", "onUpdate"]), _createElementVNode("button", {
      onClick: $event => ($data.senseInConfig = element, $data.open = true),
      class: "rounded-md bg-white px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    }, [_createVNode(_component_SettingsIcon, {
      size: "16"
    })], 8, _hoisted_4), _createElementVNode("button", {
      onClick: $event => $options.deleteSense(element),
      type: "button",
      class: "rounded-md bg-white px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    }, [_createVNode(_component_TrashIcon, {
      size: "16"
    })], 8, _hoisted_5)])]),
    _: 1
  }, 8, ["modelValue", "onChange"])) : _createCommentVNode("", true), _createElementVNode("div", null, [_createVNode(_component_ButtonComponent, {
    onClick: $options.addSense,
    color: _ctx.color
  }, {
    default: _withCtx(() => [_createVNode(_component_PlusIcon, {
      class: "h-4 w-4"
    }), _cache[5] || (_cache[5] = _createTextVNode(" Sense "))]),
    _: 1
  }, 8, ["onClick", "color"])]), _createVNode(_component_ModalHelper, {
    show: $data.open,
    onClose: _cache[4] || (_cache[4] = $event => $data.open = false)
  }, {
    title: _withCtx(() => [_createTextVNode(" Sense Configuration for " + _toDisplayString($data.senseInConfig.name), 1)]),
    default: _withCtx(() => [_createVNode(_component_SenseBuilder, {
      modelValue: $data.senseInConfig,
      "onUpdate:modelValue": [_cache[1] || (_cache[1] = $event => $data.senseInConfig = $event), _cache[2] || (_cache[2] = sense => $options.senses = $options.senses.map(s => s.id == sense.id ? sense : s))],
      entry: _ctx.entry,
      "onDelete:modelValue": _cache[3] || (_cache[3] = sense => {
        $options.senses = $options.senses.filter(s => s.id !== sense.id);
        $data.open = false;
      })
    }, null, 8, ["modelValue", "entry"])]),
    _: 1
  }, 8, ["show"])]);
}