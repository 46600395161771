import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, unref as _unref, withModifiers as _withModifiers, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["data-date"];
const _hoisted_2 = {
  class: "z-40 max-h-screen min-w-fit overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-black dark:text-neutral-400 dark:ring-neutral-700"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 1,
  class: "px-3 py-1",
  style: {
    "display": "block",
    "text-align": "center"
  }
};
const _hoisted_6 = {
  key: 2,
  class: "max-h-32 overflow-y-auto py-1",
  style: {
    "scrollbar-gutter": "stable"
  }
};
const _hoisted_7 = {
  style: {
    "font-weight": "medium"
  }
};
const _hoisted_8 = {
  style: {
    "color": "var(--color-medium-gray)",
    "font-size": "var(--font-size-smaller)"
  }
};
const _hoisted_9 = {
  key: 1,
  class: "p-3"
};
const _hoisted_10 = {
  style: {
    "display": "flex",
    "justify-content": "space-between",
    "gap": "1rem"
  }
};
const _hoisted_11 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_12 = {
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_13 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_14 = {
  style: {
    "font-weight": "bold",
    "text-transform": "uppercase"
  }
};
import { Popover, PopoverButton, PopoverPanel, PopoverOverlay } from "@headlessui/vue";
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
const __default__ = {
  // inject: ["position"],
  name: "DatePicker",
  mixins: [Scheduling, Statusing],
  props: {
    modelValue: null,
    entry: Object,
    showRecurrence: {
      type: Boolean,
      default: true
    },
    autoSetOnClick: {
      type: Boolean,
      default: true
    },
    teleport: Boolean
  },
  data() {
    return {
      cursor: this.modelValue || this.$moment().format("YYYY-MM-DD"),
      recurrenceOptions: [{
        title: "Every Day",
        value: {
          op: "nextXDays",
          x: 1
        }
      }, {
        title: "Every 2 Days",
        value: {
          op: "nextXDays",
          x: 2
        }
      }, {
        title: "2x / Week",
        value: {
          op: "nextXWeeks",
          x: 0.5
        }
      }, {
        title: "Every Week",
        value: {
          op: "nextXWeeks",
          x: 1
        }
      }, {
        title: "Every 2 Weeks",
        value: {
          op: "nextXWeeks",
          x: 2
        }
      }, {
        title: "Every Month",
        value: {
          op: "nextXMonths",
          x: 1
        }
      }, {
        title: "Every 2 Months",
        value: {
          op: "nextXMonths",
          x: 2
        }
      }, {
        title: "Every Quarter",
        value: {
          op: "nextXMonths",
          x: 3
        }
      }, {
        title: "2x / Year",
        value: {
          op: "nextXYears",
          x: 0.5
        }
      }, {
        title: "Every Year",
        value: {
          op: "nextXYears",
          x: 1
        }
      }]
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.cursor = this.modelValue || this.$moment().format("YYYY-MM-DD");
      },
      deep: true
    }
  },
  computed: {
    recurrence() {
      return this.entry?.schedule?.recurrence;
    },
    date: {
      get() {
        return this.modelValue;
      },
      set(date) {
        // console.log("date", date);
        this.$emit("update", date);
        this.$emit("update:modelValue", date);
      }
    },
    displayText() {
      return this.entry || this.date ? this.$formattedDateOp(this.date) : "Set Date";
    },
    dayOptions() {
      return Array.from({
        length: this.$moment(this.cursor).local().daysInMonth()
      }, (_, i) => i + 1).map(day => {
        const _date = this.$moment(this.$moment(this.cursor).set("date", day)).format("YYYY-MM-DD");
        return {
          title: this.$moment(_date).date().toString().padStart(2, "0") /* +
                                                                        (_date == date ? " · " + this.$moment(_date).format("ddd") : "")*/,
          date: _date,
          active: _date == this.modelValue
        };
      });
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "top-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_CalendarIcon = _resolveComponent("CalendarIcon");
      const _component_RefreshCwIcon = _resolveComponent("RefreshCwIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      const _component_CalendarComponent = _resolveComponent("CalendarComponent");
      const _component_PopoverHelper = _resolveComponent("PopoverHelper");
      return _openBlock(), _createElementBlock("div", null, [_renderSlot(_ctx.$slots, "default", {
        date: _ctx.date
      }, () => [_createVNode(_component_PopoverHelper, {
        id: "date_picker",
        teleport: __props.teleport,
        class: "relative"
      }, {
        button: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_ButtonComponent, {
          onClick: _cache[0] || (_cache[0] = e => {
            __props.autoSetOnClick === true ? __props.entry ? typeof _ctx.date != 'string' && _ctx.date !== null ? _ctx.date = _ctx.$moment.utc().format('YYYY-MM-DD') : null : typeof _ctx.date == 'undefined' || _ctx.date === null || _ctx.date.length === 0 ? _ctx.date = {
              op: 'today'
            } : null : null;
            // $emit('click', e);
          }),
          variant: "none",
          ref_key: "reference",
          ref: reference,
          class: _normalizeClass([{
            'font-semibold': _ctx.$formattedDateOp(_ctx.date) == 'today' || _ctx.$formattedDateOp(_ctx.date) == 'yesterday' && !_ctx.statusIsDone(__props.entry?.status),
            '!text-neutral-700 hover:!text-black dark:!text-neutral-400 dark:hover:!text-neutral-300': _ctx.$formattedDateOp(_ctx.date) == 'today',
            '!text-red-500 hover:!text-red-600 dark:!text-red-600 dark:hover:!text-red-500': _ctx.$formattedDateOp(_ctx.date) == 'yesterday' && __props.entry?.status && !_ctx.statusIsDone(__props.entry?.status)
          }, "group inline-flex items-center gap-1 whitespace-nowrap uppercase text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"])
        }, {
          default: _withCtx(() => [_createVNode(_component_CalendarIcon, {
            size: "14"
          }), _createElementVNode("span", {
            "data-date": _ctx.date
          }, _toDisplayString(_ctx.displayText), 9, _hoisted_1), __props.entry?.schedule?.recurrence ? (_openBlock(), _createBlock(_component_RefreshCwIcon, {
            key: 0,
            size: "16"
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["class"])])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [typeof _ctx.date != 'object' || _ctx.date === null ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_unref(PopoverButton), {
          onClick: _cache[1] || (_cache[1] = $event => _ctx.date = _ctx.$moment().format('YYYY-MM-DD')),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[11] || (_cache[11] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Today ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().format("YYYY-MM-DD"), false)), 1)]),
          _: 1
        }), _createVNode(_unref(PopoverButton), {
          onClick: _cache[2] || (_cache[2] = $event => _ctx.date = _ctx.$moment().add(1, 'days').format('YYYY-MM-DD')),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[12] || (_cache[12] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Tomorrow ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().add(1, "days").format("YYYY-MM-DD"), false)), 1)]),
          _: 1
        }), _createVNode(_unref(PopoverButton), {
          onClick: _cache[3] || (_cache[3] = $event => _ctx.date = _ctx.$moment().add(-1, 'days').format('YYYY-MM-DD')),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[13] || (_cache[13] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Yesterday ", -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$formattedDateOp(_ctx.$moment().add(-1, "days").format("YYYY-MM-DD"), false)), 1)]),
          _: 1
        }), __props.entry || _ctx.date ? (_openBlock(), _createBlock(_unref(PopoverButton), {
          key: 0,
          onClick: _cache[4] || (_cache[4] = $event => _ctx.date = {
            op: 'null'
          }),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[14] || (_cache[14] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Unset ", -1)), _createVNode(_component_TrashIcon, {
            class: "h-5 w-5"
          })]),
          _: 1
        })) : _createCommentVNode("", true), _createVNode(_component_CalendarComponent, null, {
          default: _withCtx(({
            date: _date
          } = _ctx.slotProps) => [_createElementVNode("button", {
            onClick: _withModifiers($event => _ctx.date = _date, ["prevent"]),
            class: _normalizeClass({
              'font-bold text-black dark:text-white': _date == _ctx.$moment().format('YYYY-MM-DD'),
              'h-8 w-8 rounded-full bg-neutral-900 font-bold text-white': _date == __props.entry?.schedule?.date
            })
          }, _toDisplayString(_ctx.$moment(_date).format("DD")), 11, _hoisted_4)]),
          _: 1
        }), __props.showRecurrence ? (_openBlock(), _createElementBlock("small", _hoisted_5, " Recurrence ")) : _createCommentVNode("", true), __props.showRecurrence ? (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recurrenceOptions, (option, index) => {
          return _openBlock(), _createBlock(_unref(PopoverButton), {
            key: index,
            onClick: $event => _ctx.$emit('update:recurrence', option.value),
            class: _normalizeClass([_ctx.recurrence?.op == option.value.op && _ctx.recurrence?.x == option.value.x ? 'font-bold' : null, "flex w-full justify-between px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"])
          }, {
            default: _withCtx(() => [_createElementVNode("span", _hoisted_7, _toDisplayString(option.title), 1), _createElementVNode("span", _hoisted_8, " ↻ " + _toDisplayString(_ctx.interpretDate({
              date: option.value
            })), 1)]),
            _: 2
          }, 1032, ["onClick", "class"]);
        }), 128))])) : _createCommentVNode("", true), __props.entry?.schedule?.recurrence ? (_openBlock(), _createBlock(_unref(PopoverButton), {
          key: 3,
          onClick: _cache[5] || (_cache[5] = $event => _ctx.$emit('update:recurrence', null)),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [_createElementVNode("span", {
            style: {}
          }, " Unset Recurrence ", -1), _createElementVNode("span", {
            style: {
              "color": "var(--color-medium-gray)"
            }
          }, null, -1)])),
          _: 1
        })) : _createCommentVNode("", true)])) : typeof _ctx.date == 'object' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_ctx.date?.op ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          style: _normalizeStyle([{
            color: _ctx.date.op == 'today' || _ctx.date.op == 'tomorrow' || _ctx.date.op.slice(0, 4) == 'next' && (typeof _ctx.date.x == 'undefined' || _ctx.date.x >= 0) || _ctx.date.op.slice(0, 4) == 'last' && (typeof _ctx.date.x == 'undefined' || _ctx.date.x <= 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
          }, {
            "font-size": "82%",
            "font-weight": "bold",
            "text-transform": "uppercase"
          }])
        }, " in ", 4)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_11, [_createElementVNode("button", {
          onClick: _cache[6] || (_cache[6] = _withModifiers($event => _ctx.modifyRelativeDate(-1, _ctx.date), ["prevent"]))
        }, " − "), _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.relativeTimespan(_ctx.date) ? Math.abs(_ctx.relativeTimespan(_ctx.date)[0]) : null), 1), _createElementVNode("button", {
          onClick: _cache[7] || (_cache[7] = _withModifiers($event => _ctx.modifyRelativeDate(1, _ctx.date), ["prevent"]))
        }, " + ")]), _createElementVNode("span", _hoisted_13, [_createElementVNode("button", {
          onClick: _cache[8] || (_cache[8] = _withModifiers($event => _ctx.modifyRelativeTimespan(-1, _ctx.date), ["prevent"]))
        }, " − "), _createElementVNode("button", _hoisted_14, _toDisplayString(_ctx.relativeTimespan(_ctx.date) ? _ctx.relativeTimespan(_ctx.date)[1] : null), 1), _createElementVNode("button", {
          onClick: _cache[9] || (_cache[9] = _withModifiers($event => _ctx.modifyRelativeTimespan(1, _ctx.date), ["prevent"]))
        }, " + ")]), _ctx.date?.op ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          style: _normalizeStyle([{
            color: _ctx.date.op == 'yesterday' || _ctx.date.op.slice(0, 4) == 'next' && (typeof _ctx.date.x == 'undefined' || _ctx.date.x < 0) || _ctx.date.op.slice(0, 4) == 'last' && (typeof _ctx.date.x == 'undefined' || _ctx.date.x > 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
          }, {
            "font-size": "82%",
            "font-weight": "bold",
            "text-transform": "uppercase"
          }])
        }, " ago ", 4)) : _createCommentVNode("", true)]), __props.entry ? (_openBlock(), _createBlock(_component_ButtonComponent, {
          key: 0,
          onClick: _cache[10] || (_cache[10] = _withModifiers($event => [_ctx.date = __props.entry ? {
            op: 'null'
          } : {
            op: 'unset'
          }], ["prevent"])),
          title: "unset the schedule date"
        }, {
          default: _withCtx(() => [_createVNode(_component_TrashIcon, {
            class: "h-5 w-5"
          }), _cache[16] || (_cache[16] = _createElementVNode("span", null, " Unset ", -1))]),
          _: 1
        })) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])]),
        _: 3
      }, 8, ["teleport"])])]);
    };
  }
});