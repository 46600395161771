import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";
const __default__ = {
  props: {
    modelValue: Number
  },
  data() {
    return {
      durationAsText: "",
      humanReadableDuration: "",
      showPopover: false
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    duration(val) {
      this.calculateDurationAsText();
    }
  },
  mounted() {
    this.calculateDurationAsText();
  },
  computed: {
    duration: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    times() {
      return Array.from({
        length: 24
      }, (_, hour) => {
        return ["00", "15", "30", "45"].map(minute => {
          return `${String(hour).padStart(2, "0")}:${minute}`;
        });
      }).flat();
    }
  },
  methods: {
    calculateDurationAsText() {
      const startTime = this.$moment.utc().subtract(this.duration, "seconds");
      const endTime = this.$moment.utc();
      const d = this.$moment.duration(endTime.diff(startTime));
      this.durationAsText = d.format("H[h] m[m] s[s]", {
        stopTrim: "m"
      });
    },
    isValidTime(time) {
      return /^([01]\d|2[0-3])(:[0-5]\d(:[0-5]\d)?)?$/.test(time);
    },
    handleOnFocus() {
      this.showPopover = true;
      this.$refs.reference.select();
    },
    handleOnBlur() {
      this.delayShowPopover(false);
      if (this.durationAsText === "") {
        this.duration = null;
        return;
      }
      if (this.isValidTime(this.internalVisibleTime)) {
        this.duration = this.$moment(this.internalVisibleTime, "HH:mm").diff(this.$moment.utc(this.basis), "seconds");
      }
    },
    handleCustomDurationInput(event) {
      const input = event.target.value.trim();
      if (input === "") {
        // this.customDurationAsSeconds = 0;
        this.humanReadableDuration = "";
        return;
      }
      this.interpretDuration(input);
    },
    interpretDuration(input) {
      if (input.length === 0) {
        return null;
      }
      let durationInSeconds = 0;
      let humanReadableDuration = "";
      let lastUnit = null;

      // Regular expressions for matching different variations
      const hourRegex = /^(h|ho|hour|hours)$/i;
      const minuteRegex = /^(m|min|minute|minutes)$/i;
      const secondRegex = /^(s|sec|second|seconds)$/i;

      // Split the input into parts that include both numbers and units
      const parts = input.match(/(\d+|\D+)/g);

      // Process each part
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i].trim();
        if (!isNaN(part) && part !== "") {
          // If the part is a number, check the next part for the unit
          const nextPart = parts[i + 1] ? parts[i + 1].trim() : null;
          if (nextPart && hourRegex.test(nextPart)) {
            const hours = parseInt(part);
            durationInSeconds += hours * 3600;
            humanReadableDuration += `${hours} hour${hours > 1 ? "s" : ""} `;
            lastUnit = "hours";
            i++; // Skip the next part as it is already processed
          } else if (nextPart && minuteRegex.test(nextPart)) {
            const minutes = parseInt(part);
            durationInSeconds += minutes * 60;
            humanReadableDuration += `${minutes} minute${minutes > 1 ? "s" : ""} `;
            lastUnit = "minutes";
            i++; // Skip the next part as it is already processed
          } else if (nextPart && secondRegex.test(nextPart)) {
            const seconds = parseInt(part);
            durationInSeconds += seconds;
            humanReadableDuration += `${seconds} second${seconds > 1 ? "s" : ""} `;
            lastUnit = "seconds";
            i++; // Skip the next part as it is already processed
          } else {
            // Handle partial matches for units
            if (nextPart && nextPart.toLowerCase().startsWith("h")) {
              const hours = parseInt(part);
              if (!isNaN(hours)) {
                durationInSeconds += hours * 3600;
                humanReadableDuration += `${hours} hour${hours > 1 ? "s" : ""} `;
                lastUnit = "hours";
                i++; // Skip the next part as it is already processed
              }
            } else if (nextPart && nextPart.toLowerCase().startsWith("m")) {
              const minutes = parseInt(part);
              if (!isNaN(minutes)) {
                durationInSeconds += minutes * 60;
                humanReadableDuration += `${minutes} minute${minutes > 1 ? "s" : ""} `;
                lastUnit = "minutes";
                i++; // Skip the next part as it is already processed
              }
            } else if (nextPart && nextPart.toLowerCase().startsWith("s")) {
              const seconds = parseInt(part);
              if (!isNaN(seconds)) {
                durationInSeconds += seconds;
                humanReadableDuration += `${seconds} second${seconds > 1 ? "s" : ""} `;
                lastUnit = "seconds";
                i++; // Skip the next part as it is already processed
              }
            } else {
              // Default to the last unit if no unit is specified
              if (lastUnit === "hours") {
                const minutes = parseInt(part);
                if (!isNaN(minutes)) {
                  durationInSeconds += minutes * 60;
                  humanReadableDuration += `${minutes} minute${minutes > 1 ? "s" : ""} `;
                }
              } else if (lastUnit === "minutes") {
                const seconds = parseInt(part);
                if (!isNaN(seconds)) {
                  durationInSeconds += seconds;
                  humanReadableDuration += `${seconds} second${seconds > 1 ? "s" : ""} `;
                }
              } else {
                // Default to hours if no previous unit
                const hours = parseInt(part);
                if (!isNaN(hours)) {
                  durationInSeconds += hours * 3600;
                  humanReadableDuration += `${hours} hour${hours > 1 ? "s" : ""} `;
                }
              }
            }
          }
        } else if (hourRegex.test(part)) {
          const hours = parseInt(parts[i - 1]);
          if (!isNaN(hours)) {
            durationInSeconds += hours * 3600;
            humanReadableDuration += `${hours} hour${hours > 1 ? "s" : ""} `;
            lastUnit = "hours";
          }
        } else if (minuteRegex.test(part)) {
          const minutes = parseInt(parts[i - 1]);
          if (!isNaN(minutes)) {
            durationInSeconds += minutes * 60;
            humanReadableDuration += `${minutes} minute${minutes > 1 ? "s" : ""} `;
            lastUnit = "minutes";
          }
        } else if (secondRegex.test(part)) {
          const seconds = parseInt(parts[i - 1]);
          if (!isNaN(seconds)) {
            durationInSeconds += seconds;
            humanReadableDuration += `${seconds} second${seconds > 1 ? "s" : ""} `;
            lastUnit = "seconds";
          }
        }
      }
      this.humanReadableDuration = humanReadableDuration.trim();
      return durationInSeconds;
      // console.log("Duration in seconds", durationInSeconds);
      // console.log("Human readable duration", this.humanReadableDuration);
    },
    delayShowPopover(show) {
      setTimeout(() => {
        this.showPopover = show;
      }, 100);
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'DurationTextPicker',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "bottom-start",
      whileElementsMounted: autoUpdate,
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "reference",
        ref: reference
      }, [_withDirectives(_createElementVNode("input", {
        ref: "input",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.durationAsText = $event),
        placeholder: "Enter time or start the timer",
        onFocus: _cache[1] || (_cache[1] = () => {
          _ctx.$refs.input.select();
          _ctx.showPopover = true;
        }),
        onBlur: _cache[2] || (_cache[2] = (...args) => _ctx.handleOnBlur && _ctx.handleOnBlur(...args)),
        onInput: _cache[3] || (_cache[3] = (...args) => _ctx.handleCustomDurationInput && _ctx.handleCustomDurationInput(...args)),
        onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(() => {
          _ctx.$refs.input.blur();
          _ctx.duration = _ctx.interpretDuration(_ctx.durationAsText);
          _ctx.showPopover = false;
        }, ["stop", "prevent"]), ["enter"])),
        class: _normalizeClass(_ctx.$style.input)
      }, null, 34), [[_vModelText, _ctx.durationAsText]]), _ctx.showPopover && _ctx.humanReadableDuration ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "floating",
        ref: floating,
        onClick: _cache[5] || (_cache[5] = $event => _ctx.customDuration = _ctx.humanReadableDuration),
        class: _normalizeClass(_ctx.$style.popover),
        style: _normalizeStyle(_unref(floatingStyles))
      }, _toDisplayString(_ctx.humanReadableDuration), 7)) : _createCommentVNode("", true)], 512);
    };
  }
});