import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  ref: "root"
};
const _hoisted_2 = {
  key: 0,
  "data-initializing": ""
};
const _hoisted_3 = {
  class: "flex flex-col gap-y-2 py-1"
};
const _hoisted_4 = {
  class: "min-w-max bg-white p-2 dark:bg-black"
};
const _hoisted_5 = {
  class: "min-w-max bg-white p-2 dark:bg-black"
};
const _hoisted_6 = ["data-skip-scrollparent"];
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OutputSettings = _resolveComponent("OutputSettings");
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_InputDisplay = _resolveComponent("InputDisplay");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  const _component_CalendarHelper = _resolveComponent("CalendarHelper");
  const _component_OutputGroupHeader = _resolveComponent("OutputGroupHeader");
  const _component_OutputTableHeaderRow = _resolveComponent("OutputTableHeaderRow");
  const _component_EntryDisplay = _resolveComponent("EntryDisplay");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay", true);
  const _component_IntersectionList = _resolveComponent("IntersectionList");
  const _component_DropZone = _resolveComponent("DropZone");
  const _component_ListPlusIcon = _resolveComponent("ListPlusIcon");
  return $options.output ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    ref: "boundaries",
    class: _normalizeClass({
      [_ctx.$style.outputDisplay]: true
    }),
    style: _normalizeStyle($options.cssProps)
  }, [_createElementVNode("div", _hoisted_1, [!$data.isLazyMounted ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_OutputGroupHeader])
  }, null, 2), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_EntryWrapper])
  }, null, 2), _createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_EntryWrapper])
  }, null, 2), _createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.skeletonLoader, _ctx.$style.skeletonLoader_EntryWrapper])
  }, null, 2)])])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "flex flex-col gap-2 font-normal",
    onClick: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["stop"])),
    role: "list"
  }, [_renderSlot(_ctx.$slots, "output-before", {
    entries: $data.entries
  }), ['calendar', 'calendar_year', 'calendar_month', 'calendar_week', 'calendar_day'].includes($options.renderType) ? (_openBlock(), _createBlock(_component_CalendarHelper, {
    key: 0,
    mode: $options.output.type.split('_')[1],
    entry: $props.entry,
    entries: $data.entries,
    dateSelected: $props.dateSelected,
    "onUpdate:dateSelected": _cache[3] || (_cache[3] = $event => _ctx.$emit('update:dateSelected', $event)),
    onMode: _cache[4] || (_cache[4] = mode => {
      $options.output = {
        ...$options.output,
        ...{
          type: 'calendar_' + mode
        }
      };
    }),
    schema: $options.input?.schema,
    color: _ctx.getColor($props.entry),
    position: $props.position
  }, {
    header: _withCtx(() => [$props.editable ? (_openBlock(), _createBlock(_component_OutputSettings, {
      key: 0,
      modelValue: $options.output,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.output = $event),
      entry: $props.entry,
      showDelete: false,
      size: "sm",
      variant: "minimal",
      class: "hidden flex-1 flex-wrap justify-center gap-3 sm:flex",
      permissions: {
        type: true,
        group: false,
        filter: true,
        sort: false
      }
    }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true)]),
    "month-day": _withCtx(({
      date
    }) => [_createVNode(_component_PopoverHelper, {
      class: "absolute bottom-2 left-2"
    }, {
      button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
        variant: "round",
        size: "xs",
        class: "!bg-neutral-100 !text-neutral-700 dark:!bg-neutral-900 dark:!text-neutral-100"
      }, {
        default: _withCtx(() => [_createVNode(_component_PlusIcon, {
          size: "20"
        })]),
        _: 1
      })]),
      default: _withCtx(({
        close
      }) => [_createElementVNode("div", _hoisted_4, [$props.entry.input ? (_openBlock(), _createBlock(_component_InputDisplay, {
        key: 0,
        modelValue: $options.input,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.input = $event),
        schema: {
          ...$options.input?.schema,
          ...{
            schedule: {
              date: date.format('YYYY-MM-DD')
            }
          }
        },
        display: $props.display,
        entry: $props.entry,
        position: $props.position,
        editable: true,
        onCreated: entry => {
          close();
          _ctx.$emit('created', entry);
        },
        disableRouting: $props.disableRouting
      }, null, 8, ["modelValue", "schema", "display", "entry", "position", "onCreated", "disableRouting"])) : _createCommentVNode("", true)])]),
      _: 2
    }, 1024)]),
    "day-slot": _withCtx(({
      date,
      time
    }) => [_createVNode(_component_PopoverHelper, {
      class: "absolute inset-0"
    }, {
      button: _withCtx(() => _cache[15] || (_cache[15] = [_createElementVNode("div", {
        class: "hover:_bg-neutral-50 dark:_bg-neutral-900 h-full w-full"
      }, null, -1)])),
      default: _withCtx(({
        close
      }) => [_createElementVNode("div", _hoisted_5, [$props.entry.input ? (_openBlock(), _createBlock(_component_InputDisplay, {
        key: 0,
        modelValue: $options.input,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $options.input = $event),
        schema: {
          ...$options.input?.schema,
          ...{
            schedule: {
              date: date.format('YYYY-MM-DD'),
              time: time
            }
          }
        },
        display: $props.display,
        entry: $props.entry,
        position: $props.position,
        editable: true,
        onCreated: entry => {
          close();
          _ctx.$emit('created', entry);
        },
        disableRouting: $props.disableRouting
      }, null, 8, ["modelValue", "schema", "display", "entry", "position", "onCreated", "disableRouting"])) : _createCommentVNode("", true)])]),
      _: 2
    }, 1024)]),
    _: 1
  }, 8, ["mode", "entry", "entries", "dateSelected", "schema", "color", "position"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass([[_ctx.$style['outputDisplay_' + $options.renderType]], "relative"]),
    "data-skip-scrollparent": $options.renderType == 'board',
    style: {
      "scrollbar-gutter": "stable"
    }
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groups /*.slice(
                                                                                          renderType == 'board' && offset ? offset : 0,
                                                                                          renderType == 'board' && limit
                                                                                          ? (offset ? offset : 0) + limit
                                                                                          : undefined,
                                                                                          )*/, (group, groupIndex) => {
    return _openBlock(), _createBlock(_component_DropZone, {
      key: groupIndex,
      class: _normalizeClass({
        [_ctx.$style['groupWrapper']]: true,
        [_ctx.$style['groupWrapper_' + $options.renderType]]: true,
        [_ctx.$style.groupWrapperWithSettings]: $data.groupIsHovered == groupIndex || $data.groupHasActivePopover == groupIndex
      }),
      onMouseenter: $event => $data.groupIsHovered = groupIndex,
      onMouseleave: _cache[12] || (_cache[12] = $event => $data.groupIsHovered = -1),
      onOnDrop: schema => $options.onDrop(schema, group),
      orientation: $options.renderType == 'gallery' && $data.maxGridColumns > 1 ? 'horizontal' : 'vertical',
      dropSchema: group.schema,
      onDrop: () => {
        // $console.log(
        //   'old @drop handler in DropZone in OutputDisplay',
        // );
        /*onDrop(group.schema)*/
      },
      onDragover: _withModifiers($event => null, ["prevent"]),
      onDragenter: _withModifiers($event => null, ["prevent"]),
      "data-grid-columns": $data.maxGridColumns
    }, {
      default: _withCtx(() => [$props.showHeader && !['search', 'left'].includes($props.position) ? (_openBlock(), _createBlock(_component_OutputGroupHeader, {
        key: 0,
        modelValue: $options.output,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $options.output = $event),
        entry: $props.entry,
        group: group,
        editable: $props.editable,
        disabled: $props.disabled,
        groupEntries: $data.entries.filter(group.filter),
        class: _normalizeClass(_ctx.$style.outputGroupHeader),
        renderType: $options.renderType
      }, {
        default: _withCtx(() => [$props.editable && $options.renderType != 'board' ? (_openBlock(), _createBlock(_component_OutputSettings, {
          key: 0,
          modelValue: $options.output,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $options.output = $event),
          entry: $props.entry,
          showDelete: false,
          size: "xs",
          variant: "minimal",
          "onOn:popoverOpen": $event => $data.groupHasActivePopover = groupIndex,
          "onOn:popoverClose": _cache[6] || (_cache[6] = $event => $data.groupHasActivePopover = -1),
          class: _normalizeClass(_ctx.$style.outputSettings),
          "data-tour": "output_settings"
        }, null, 8, ["modelValue", "entry", "onOn:popoverOpen", "class"])) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["modelValue", "entry", "group", "editable", "disabled", "groupEntries", "class", "renderType"])) : _createCommentVNode("", true), $options.renderType == 'table' ? (_openBlock(), _createBlock(_component_OutputTableHeaderRow, {
        key: 1,
        modelValue: $options.output.settings.table.columns,
        "onUpdate:modelValue": [_cache[8] || (_cache[8] = $event => $options.output.settings.table.columns = $event), _cache[9] || (_cache[9] = columns => {
          $options.output = {
            ...$options.output,
            ...{
              settings: {
                table: {
                  columns
                }
              }
            }
          };
        })],
        entry: $props.entry
      }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), _createVNode(_component_IntersectionList, {
        items: $data.entries.filter(group.filter),
        preserveItems: $data.renderOutput,
        itemHeight: 32,
        itemClass: [_ctx.$style.entryWrapper, _ctx.$style['entryWrapper_' + $options.renderType]]
      }, {
        item: _withCtx(({
          item: entry,
          index
        }) => [_createElementVNode("div", {
          ref_for: true,
          ref: 'entry_' + index,
          onClick: $event => _ctx.$emit('onEntryClick', entry),
          class: _normalizeClass({
            [`${_ctx.$style.entryDisplay_highlighted}`]: $props.experimentalHighlightIndex == index
          })
        }, [_createVNode(_component_EntryDisplay, {
          modelValue: entry,
          position: $props.position,
          disabled: $props.entriesDisabled,
          permissions: $options.entryPermissions,
          renderType: $props.overrideEntryRenderType ? $props.overrideEntryRenderType : $options.renderType,
          output: $options.output,
          display: $data.cachedDisplay,
          disableViewportChecking: $props.disableViewportChecking,
          entryIndex: index,
          dropIndex: index,
          containingOutputGroup: entry?.id + '_' + groupIndex,
          size: $props.size,
          isRenderOutput: $data.renderOutput.includes(entry.id),
          toggleRenderOutput: $options.toggleRenderOutput,
          class: _normalizeClass({
            [`${_ctx.$style.entryDisplay}`]: true,
            [`${_ctx.$style.entryDisplay_withInput}`]: entry.input && !_ctx.$store.getters.dragged.map(e => e.id).includes(entry.id)
          })
        }, null, 8, ["modelValue", "position", "disabled", "permissions", "renderType", "output", "display", "disableViewportChecking", "entryIndex", "dropIndex", "containingOutputGroup", "size", "isRenderOutput", "toggleRenderOutput", "class"])], 10, _hoisted_7), entry.output && $data.renderOutput.includes(entry.id) ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style.outputDisplay_wrapper)
        }, [_createVNode(_component_OutputDisplay, {
          modelValue: entry.output,
          entry: entry,
          showHeader: false,
          sorting: entry.output.sorting,
          display: $data.cachedDisplay,
          position: $props.position,
          overrideEntryRenderType: entry.output.type ? entry.output.type : $options.renderType /* note: this is for board > opening child entries showing links with basis-full */,

          editable: false,
          size: $props.size
        }, null, 8, ["modelValue", "entry", "sorting", "display", "position", "overrideEntryRenderType", "size"])], 2)) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["items", "preserveItems", "itemClass"]), $props.showInput && $props.position != 'search' && $props.entry.input && group.schema ? (_openBlock(), _createElementBlock("div", {
        key: 2,
        class: _normalizeClass([_ctx.$style.entryWrapper, _ctx.$style.inputWrapper])
      }, [_createVNode(_component_InputDisplay, {
        modelValue: $options.input,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => $options.input = $event),
        entry: $props.entry,
        output: $options.output,
        schema: /*$merge(input?.schema, */group.schema /*)*/,
        display: $props.display ? {
          ...$props.display,
          ...$options.renderTypeDisplay
        } : $props.display,
        position: $props.position,
        renderType: $options.renderType,
        color: $props.entry.color,
        editable: $props.position != 'left' && $props.editable,
        disabled: /*disabled*/false,
        class: _normalizeClass({
          '_shadow-lg _rounded-md _p-2': $options.renderType == 'gallery'
          // 'px-2 py-1': position == 'left',
        }),
        onCreated: _cache[11] || (_cache[11] = entry => _ctx.$emit('created', entry))
      }, null, 8, ["modelValue", "entry", "output", "schema", "display", "position", "renderType", "color", "editable", "class"])], 2)) : _createCommentVNode("", true)]),
      _: 2
    }, 1032, ["class", "onMouseenter", "onOnDrop", "orientation", "dropSchema", "data-grid-columns"]);
  }), 128))], 10, _hoisted_6)), _renderSlot(_ctx.$slots, "output-after", {
    entries: $data.entries,
    pages: $options.totalPages
  })]))], 512)], 6)) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_ButtonComponent, {
    onClick: _cache[14] || (_cache[14] = $event => $options.output = $data.addonBlueprint('output', $props.entry, this)),
    class: "w-full"
  }, {
    default: _withCtx(() => [_createVNode(_component_ListPlusIcon, {
      size: "20"
    }), _cache[16] || (_cache[16] = _createTextVNode()), _cache[17] || (_cache[17] = _createElementVNode("span", null, " Add Output", -1))]),
    _: 1
  })]));
}