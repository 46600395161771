import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "items-center; flex flex-wrap gap-1"
};
const _hoisted_2 = {
  class: "flex w-[320px] flex-col gap-4 p-3 md:w-[500px] lg:w-[700px]"
};
const _hoisted_3 = {
  href: "https://help.pensive.io/outputs",
  target: "_blank",
  class: "text-sm text-blue-500 hover:text-blue-700 hover:underline"
};
const _hoisted_4 = {
  class: "flex w-[320px] flex-col gap-4 p-3 md:w-[500px] lg:w-[700px]"
};
const _hoisted_5 = {
  href: "https://help.pensive.io/outputs#sortings",
  target: "_blank",
  class: "text-sm text-blue-500 hover:text-blue-700 hover:underline"
};
const _hoisted_6 = {
  key: 4
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 1
};
const _hoisted_9 = {
  key: 0
};
import { addonBlueprint } from "@/addonBlueprint";
import { FilterIcon, ListPlusIcon } from "lucide-vue-next";
import GroupingPicker from "../builders/GroupingPicker.vue";
import OutputBuilder from "../builders/OutputBuilder.vue";
import SortingBuilder from "../builders/SortingBuilder.vue";
import { Addon } from "../mixins/Addon";
import { defineAsyncComponent, hydrateOnIdle } from "vue";
const __default__ = {
  mixins: [Addon],
  inject: {
    renderType: {
      default: "list"
    }
  },
  props: {
    showDelete: {
      type: Boolean,
      default: true
    },
    permissions: {
      type: Object,
      default: () => ({
        type: true,
        group: true,
        filter: true,
        sort: true
      })
    }
  },
  components: {
    AspectTypePicker: defineAsyncComponent({
      loader: () => import("../builders/AspectTypePicker.vue"),
      hydrate: hydrateOnIdle
    }),
    GroupingPicker,
    OutputBuilder,
    SortingBuilder,
    // ICONS
    FilterIcon
  },
  data() {
    return {
      temporaryFilters: null
    };
  },
  computed: {
    output: {
      get() {
        return this.modelValue;
      },
      set(output) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.output = output;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'OutputSettings',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_AspectTypePicker = _resolveComponent("AspectTypePicker");
      const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
      const _component_CircleHelpIcon = _resolveComponent("CircleHelpIcon");
      const _component_PopoverHelper = _resolveComponent("PopoverHelper");
      const _component_ChevronsUpDownIcon = _resolveComponent("ChevronsUpDownIcon");
      const _component_LabelHelper = _resolveComponent("LabelHelper");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      return _ctx.output ? (_openBlock(), _createElementBlock("div", _hoisted_1, [__props.permissions.type ? (_openBlock(), _createBlock(_component_AspectTypePicker, {
        key: 0,
        modelValue: _ctx.output.type,
        "onUpdate:modelValue": [_cache[0] || (_cache[0] = $event => _ctx.output.type = $event), _cache[1] || (_cache[1] = type => {
          _ctx.output = {
            ..._ctx.output,
            ...{
              type: type
            }
          };
          if (type == 'table') {
            _ctx.output = {
              ..._ctx.output,
              ...{
                settings: {
                  table: {
                    columns: [{
                      field: 'name',
                      width: 120
                    }, {
                      field: 'status',
                      width: 80
                    }, {
                      field: 'description',
                      width: 150
                    }]
                  }
                }
              }
            };
          }
        })],
        size: _ctx.size,
        variant: _ctx.variant,
        color: _ctx.color
      }, null, 8, ["modelValue", "size", "variant", "color"])) : _createCommentVNode("", true), __props.permissions.group ? (_openBlock(), _createBlock(GroupingPicker, {
        key: 1,
        modelValue: _ctx.output.grouping,
        "onUpdate:modelValue": [_cache[2] || (_cache[2] = $event => _ctx.output.grouping = $event), _cache[3] || (_cache[3] = grouping => {
          _ctx.output = {
            ..._ctx.output,
            ...{
              grouping: grouping
            }
          };
        })],
        size: _ctx.size,
        variant: _ctx.variant,
        color: _ctx.color
      }, null, 8, ["modelValue", "size", "variant", "color"])) : _createCommentVNode("", true), __props.permissions.filter ? (_openBlock(), _createBlock(_component_PopoverHelper, {
        key: 2,
        id: "output_builder",
        teleport: true,
        canOverflow: true,
        onOpen: _cache[6] || (_cache[6] = $event => _ctx.$emit('on:popover-open')),
        onClose: _cache[7] || (_cache[7] = $event => (_ctx.temporaryFilters !== null ? _ctx.output = {
          ..._ctx.output,
          ...{
            filters: _ctx.temporaryFilters
          }
        } : null, _ctx.$emit('on:popover-close')))
      }, {
        button: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
          title: "Filter Output",
          class: _normalizeClass(_ctx.$style.settingsButton)
        }, {
          default: _withCtx(() => [_createVNode(_unref(FilterIcon), {
            size: "16",
            strokeWidth: "1.5"
          })]),
          _: 1
        }, 8, ["class"])]),
        default: _withCtx(({
          close
        }) => [_createElementVNode("div", _hoisted_2, [_createVNode(OutputBuilder, {
          id: "output_builder_inner",
          modelValue: _ctx.output.filters,
          "onUpdate:modelValue": [_cache[4] || (_cache[4] = $event => _ctx.output.filters = $event), filters => {
            close();
            _ctx.output = {
              ..._ctx.output,
              ...{
                filters: filters
              }
            };
          }],
          entry: _ctx.entry,
          onOnChange: _cache[5] || (_cache[5] = data => {
            _ctx.temporaryFilters = data;
          })
        }, null, 8, ["modelValue", "entry", "onUpdate:modelValue"]), _createElementVNode("a", _hoisted_3, [_createVNode(_component_CircleHelpIcon, {
          size: "16",
          class: "mr-1 inline"
        }), _cache[14] || (_cache[14] = _createElementVNode("span", null, "Learn more about filters", -1))])])]),
        _: 1
      })) : _createCommentVNode("", true), __props.permissions.sort ? (_openBlock(), _createBlock(_component_PopoverHelper, {
        key: 3,
        canOverflow: true,
        teleport: true,
        onOpen: _cache[10] || (_cache[10] = $event => _ctx.$emit('on:popover-open')),
        onClose: _cache[11] || (_cache[11] = $event => _ctx.$emit('on:popover-close'))
      }, {
        button: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
          title: "Sort Output",
          class: _normalizeClass(_ctx.$style.settingsButton)
        }, {
          default: _withCtx(() => [_createVNode(_component_ChevronsUpDownIcon, {
            size: "16",
            strokeWidth: "1.5"
          })]),
          _: 1
        }, 8, ["class"])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_LabelHelper, null, {
          default: _withCtx(() => [_createVNode(_component_ChevronsUpDownIcon, {
            size: "16"
          }), _cache[15] || (_cache[15] = _createElementVNode("span", null, "Sorting", -1))]),
          _: 1
        }), _createVNode(SortingBuilder, {
          modelValue: _ctx.output.sorting,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _ctx.output.sorting = $event),
          onUpdate: _cache[9] || (_cache[9] = e => {
            _ctx.output = {
              ..._ctx.output,
              ...{
                sorting: e,
                custom_sorting: []
              }
            };
          })
        }, null, 8, ["modelValue"]), _createElementVNode("a", _hoisted_5, [_createVNode(_component_CircleHelpIcon, {
          size: "16",
          class: "mr-1 inline"
        }), _cache[16] || (_cache[16] = _createElementVNode("span", null, "Learn more about sorting", -1))])])]),
        _: 1
      })) : _createCommentVNode("", true), __props.showDelete ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_ButtonComponent, {
        onClick: _cache[12] || (_cache[12] = $event => _ctx.output = null),
        size: _ctx.size,
        variant: _ctx.variant,
        color: _ctx.color,
        title: "Delete Output"
      }, {
        default: _withCtx(() => [_createVNode(_component_TrashIcon, {
          size: "16"
        }), _ctx.variant != 'minimal' ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Delete")) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["size", "variant", "color"])])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_ButtonComponent, {
        onClick: _cache[13] || (_cache[13] = $event => _ctx.output = _unref(addonBlueprint)('output', _ctx.entry, this)),
        size: _ctx.size,
        variant: _ctx.variant,
        color: _ctx.color,
        title: "Add Output",
        shortcode: "o"
      }, {
        default: _withCtx(() => [_createVNode(_unref(ListPlusIcon), {
          size: "20"
        }), !['round', 'minimal'].includes(_ctx.variant) ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Output ")) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["size", "variant", "color"])]));
    };
  }
});