import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex items-center gap-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon");
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon");
  const _component_TimeTrackingsRow = _resolveComponent("TimeTrackingsRow");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("button", {
    onClick: _cache[0] || (_cache[0] = $event => $data.open = !$data.open),
    class: _normalizeClass(_ctx.$style.userHeader)
  }, [_createElementVNode("div", _hoisted_1, [!$data.open ? (_openBlock(), _createBlock(_component_ChevronRightIcon, {
    key: 0,
    size: "16"
  })) : (_openBlock(), _createBlock(_component_ChevronDownIcon, {
    key: 1,
    size: "16"
  })), _createElementVNode("span", null, _toDisplayString($props.user.name), 1)]), _createElementVNode("div", null, _toDisplayString(_ctx.$moment.utc($options.time_trackings?.filter(tt => tt.end_at !== null).map(tt => {
    if (!_ctx.$moment.utc(tt.start_at).isValid() || !_ctx.$moment.utc(tt.end_at).isValid()) return 0;
    return _ctx.$moment.utc(tt.end_at).unix() - _ctx.$moment.utc(tt.start_at).unix();
  }).reduce((a, b) => a + b, 0) * 1000).format("H:mm:ss")), 1)], 2), $data.open ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.timeTrackingRows)
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.time_trackings.filter(tt => tt.end_at).sort((a, b) => _ctx.$moment.utc(b.start_at).unix() - _ctx.$moment.utc(a.start_at).unix()), (time_tracking, index) => {
    return _openBlock(), _createBlock(_component_TimeTrackingsRow, {
      time_tracking: time_tracking,
      entry: $props.entry,
      key: index
    }, null, 8, ["time_tracking", "entry"]);
  }), 128))], 2)) : _createCommentVNode("", true)]);
}