import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, Transition as _Transition, Teleport as _Teleport, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "ps_output_group_header flex flex-wrap items-center justify-between gap-2 sm:flex-nowrap",
  "data-tour": "output_group_header"
};
const _hoisted_2 = {
  class: "flex flex-1 cursor-default flex-wrap items-center gap-2"
};
const _hoisted_3 = {
  key: 0,
  class: "text-sm font-semibold text-neutral-700 dark:text-neutral-300"
};
const _hoisted_4 = ["onClick"];
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
const __default__ = {
  props: {
    modelValue: Object,
    entry: Object,
    group: Object,
    groupEntries: Array,
    editable: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    renderType: {
      type: String,
      default: "list"
    }
  },
  data() {
    return {
      showConfig: false,
      showModal: false
    };
  },
  computed: {
    // entry: {
    //   get() {
    //     return this.modelValue;
    //   },
    //   // set(value) {
    //   //   this.$emit("update:modelValue", value);
    //   // },
    // },
    output: {
      get() {
        return this.modelValue;
      },
      set(output) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.output = output;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: {
            entry: this.entry
          },
          entry: this.entry
        });
      }
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'OutputGroupHeader',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "bottom-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_TourHelper = _resolveComponent("TourHelper");
      const _component_EllipsisVerticalIcon = _resolveComponent("EllipsisVerticalIcon");
      const _component_MenuButton = _resolveComponent("MenuButton");
      const _component_OverlayComponent = _resolveComponent("OverlayComponent");
      const _component_MenuItem = _resolveComponent("MenuItem");
      const _component_MenuItems = _resolveComponent("MenuItems");
      const _component_Menu = _resolveComponent("Menu");
      const _component_OutputSettings = _resolveComponent("OutputSettings");
      const _component_ModalHelper = _resolveComponent("ModalHelper");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [__props.group.name ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(__props.group.name), 1)) : _createCommentVNode("", true), __props.entry.id == _ctx.$route.params.id ? (_openBlock(), _createBlock(_component_TourHelper, {
        key: 1,
        hook: "output_settings",
        size: "sm",
        variant: "minimal",
        class: "h-5"
      })) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default")]), _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = $event => __props.groupEntries.forEach(e => _ctx.$store.dispatch('selected', e))),
        class: _normalizeClass([_ctx.$style.interactableElements, "cursor-pointer text-xs"])
      }, _toDisplayString(__props.groupEntries ? __props.groupEntries?.length + " " + (__props.groupEntries?.length != 1 ? "Entries" : "Entry") : ""), 3), !__props.disabled ? (_openBlock(), _createBlock(_component_Menu, {
        key: 0,
        as: "div"
      }, {
        default: _withCtx(({
          open
        }) => [_createElementVNode("div", null, [_createVNode(_component_MenuButton, {
          ref_key: "reference",
          ref: reference,
          class: "flex items-start rounded-full bg-gray-100 align-text-top text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 focus:ring-offset-gray-100 focus-visible:outline-none dark:bg-neutral-900 dark:focus:ring-offset-neutral-900"
        }, {
          default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("span", {
            class: "sr-only"
          }, "Open options", -1)), _createVNode(_component_EllipsisVerticalIcon, {
            class: "h-5 w-5",
            "aria-hidden": "true"
          })]),
          _: 1
        }, 512)]), (_openBlock(), _createBlock(_Teleport, {
          to: "body"
        }, [open ? (_openBlock(), _createBlock(_component_OverlayComponent, {
          key: 0,
          onClick: $event => open = false
        }, null, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-100",
          "enter-from-class": "transform opacity-0 scale-95",
          "enter-to-class": "transform opacity-100 scale-100",
          "leave-active-class": "transition ease-in duration-75",
          "leave-from-class": "transform opacity-100 scale-100",
          "leave-to-class": "transform opacity-0 scale-95"
        }, {
          default: _withCtx(() => [_createVNode(_component_MenuItems, {
            class: "absolute right-0 z-[999] mt-2"
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              ref_key: "floating",
              ref: floating,
              style: _normalizeStyle(_unref(floatingStyles)),
              class: "w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black dark:ring-neutral-700"
            }, [_createVNode(_component_MenuItem, null, {
              default: _withCtx(({
                active
              }) => [_createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = $event => __props.groupEntries.forEach(e => _ctx.$store.dispatch('selected', e))),
                class: _normalizeClass([active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-gray-700 dark:text-neutral-400', 'block cursor-pointer px-4 py-2 text-sm'])
              }, " Select all ", 2)]),
              _: 1
            }), __props.editable ? (_openBlock(), _createBlock(_component_MenuItem, {
              key: 0
            }, {
              default: _withCtx(({
                active
              }) => [_createElementVNode("a", {
                onClick: _cache[2] || (_cache[2] = $event => _ctx.showModal = true),
                class: _normalizeClass([active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-gray-700 dark:text-neutral-400', 'block  cursor-pointer px-4 py-2 text-sm'])
              }, " Edit Output ", 2)]),
              _: 1
            })) : _createCommentVNode("", true), __props.editable ? (_openBlock(), _createBlock(_component_MenuItem, {
              key: 1
            }, {
              default: _withCtx(({
                active
              }) => [_createElementVNode("a", {
                onClick: _cache[3] || (_cache[3] = $event => _ctx.output = null),
                class: _normalizeClass([active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-gray-700 dark:text-neutral-400', 'block cursor-pointer px-4 py-2 text-sm'])
              }, " Delete Output ", 2)]),
              _: 1
            })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.group.actions, (action, index) => {
              return _openBlock(), _createBlock(_component_MenuItem, {
                key: index
              }, {
                default: _withCtx(({
                  active
                }) => [_createElementVNode("a", {
                  onClick: $event => action.fn(__props.groupEntries),
                  class: _normalizeClass([active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300' : 'text-gray-700 dark:text-neutral-400', 'block cursor-pointer px-4 py-2 text-sm'])
                }, _toDisplayString(action.name), 11, _hoisted_4)]),
                _: 2
              }, 1024);
            }), 128))], 4)]),
            _: 1
          })]),
          _: 1
        })]))]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_ModalHelper, {
        show: _ctx.showModal,
        onClose: _cache[5] || (_cache[5] = $event => _ctx.showModal = false)
      }, {
        title: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode(" Edit Output ")])),
        default: _withCtx(() => [_createVNode(_component_OutputSettings, {
          modelValue: _ctx.output,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.output = $event),
          entry: __props.entry,
          color: "secondary"
        }, null, 8, ["modelValue", "entry"])]),
        _: 1
      }, 8, ["show"])]);
    };
  }
});