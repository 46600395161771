import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition } from "vue";
const _hoisted_1 = {
  class: "flex items-center gap-x-4 p-2 sm:hidden"
};
const _hoisted_2 = {
  class: "flex h-14 items-center p-2"
};
const _hoisted_3 = {
  ref: "listbox_button",
  class: "pointer-events-none leading-[0]"
};
const _hoisted_4 = ["onClick", "active"];
import anime from "animejs";
import { Schema } from "./mixins/Schema";
import { Statusing } from "./mixins/Statusing";
import { Scheduling } from "./mixins/Scheduling";
const __default__ = {
  props: {
    entry: Object,
    scrollY: Number
  },
  mixins: [Schema, Statusing, Scheduling],
  data() {
    return {
      // scrollY: 0,
      mouseDown: false,
      interval: null,
      timeout: null,
      mainFrame: null
      // input: null,
    };
  },
  watch: {
    // entry: {
    //   handler() {
    //     this.detectInput();
    //   },
    //   deep: true,
    // },
    // "entries.length": function () {
    //   this.detectInput();
    // },
    // "$store.getters.initialized": function () {
    //   this.detectInput();
    // },
    "draggedEntries.length": function (n) {
      if (n > 0) {
        window.addEventListener("touchmove", this.onTouchMove);
      } else {
        window.removeEventListener("touchmove", this.onTouchMove);
      }
    }
  },
  computed: {
    inputs() {
      return this.$store.getters.registeredInputs.filter(input => input.position == "center");
    },
    entries() {
      return this.$store.getters.entries.filter(e => e.deleted_at === null);
    },
    linkedEntries() {
      return this.entry.links.map(id => this.entries.find(e => e.id == id)).filter(e => e);
    },
    anchors() {
      return this.entries?.filter(e => e.anchor).sort((a, b) => {
        if (a.anchor.position > b.anchor.position) return 1;
        if (a.anchor.position < b.anchor.position) return -1;
        return 0;
      });
    },
    draggedEntries() {
      return this.$store.getters.dragged;
    }
  },
  mounted() {
    this.mainFrame = document.getElementById("main");
    // if (this.mainFrame)
    //   this.mainFrame.addEventListener("scroll", this.onScroll);
    // this.onScroll();
    this.$nextTick(() => {
      this.$emit("mounted");
    });
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
    // if (this.mainFrame)
    //   this.mainFrame.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onKeyDown(event) {
      if (this.$refs.button && event.key == "+" && !event.repeat && event.target.tagName !== "INPUT" && event.target.isContentEditable !== true && !this.isElementObscured(this.$refs.button)) {
        this.$refs.button.dispatchEvent(new MouseEvent("mousedown", {
          bubbles: true
        }));
      }
    },
    onKeyUp(event) {
      if (this.$refs.button && event.key == "+" && !event.repeat && event.target.tagName !== "INPUT" && event.target.isContentEditable !== true && !this.isElementObscured(this.$refs.button)) {
        this.$refs.button.dispatchEvent(new MouseEvent("mouseup", {
          bubbles: true
        }));
      }
    },
    isElementObscured(element) {
      const rect = element.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const topElement = document.elementFromPoint(centerX, centerY);
      return topElement !== element && !element.contains(topElement);
    },
    focusInput(input) {
      if (navigator.userAgent.includes("Mobile") && this.mainFrame) this.mainFrame.style.overflowY = "hidden";
      input.obj.$refs.entry_display.isInitialized = true;
      this.$nextTick(() => {
        input.el.querySelector("[contenteditable]").click();
        this.$nextTick(() => {
          input.el.querySelector("[contenteditable]").focus({
            preventScroll: true
          });
          setTimeout(() => {
            if (navigator.userAgent.includes("Mobile") && this.mainFrame) this.mainFrame.style.overflowY = "auto";
            input.el.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest"
            });
          }, 100);
        });
      });
    },
    onMouseEnter() {
      anime({
        targets: this.$refs.button,
        scale: 1.25
      });
    },
    onMouseLeave() {
      anime({
        targets: this.$refs.button,
        scale: 1
      });
    },
    onMouseDown(e) {
      // console.log("onMouseDown", e);
      anime({
        targets: this.$refs.button,
        scale: 1.5
      });

      // console.log("onMouseDown", e);
      e.stopPropagation();
      if (e.type != "touchstart") e.preventDefault();
      // if (e.button === 0) {
      this.mouseDown = true;
      this.timeout = setTimeout(() => {
        if (this.mouseDown) {
          this.mouseDown = false;
          anime({
            targets: this.$refs.button,
            scale: 1
          });
          this.$refs.listbox_button.click();
          this.timeout = null;
        }
      }, 500);
      // }
    },
    onMouseUp(e) {
      anime({
        targets: this.$refs.button,
        scale: 1
      });
      // console.log("onMouseUp", e);
      e.stopPropagation();
      e.preventDefault();
      // if (e.button === 0) {
      this.mouseDown = false;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.focusInput(this.inputs[0]);
      } else {
        clearTimeout(this.timeout);
      }
      this.timeout = null;
      // }
    },
    onTouchMove(e) {
      if (e.touches && e.touches.length > 0) {
        e.preventDefault();
        e.stopPropagation();
        const touch = e.touches[0];
        const el = document.elementFromPoint(touch.clientX, touch.clientY);
        if (el) {
          const button = el.closest("#left_sidebar_toggle_mobile");
          if (button) {
            this.$emit("toggleSidebarMobile");
          }
        }
      }
    },
    // onScroll() {
    //   this.scrollY = this.mainFrame.scrollTop;
    // },
    inputSchemaWithId(schema, name) {
      const base = {
        temp: true,
        id: this.$nanoid(),
        space_id: this.space?.id || this.$store.getters.user?.current_space_id,
        status_id: null,
        name: name || "Unnamed",
        statuses: [],
        custom_fields: [],
        custom_values: [],
        time_trackings: [],
        senses: [],
        links: [],
        backlinks: [],
        created_at: this.$moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        updated_at: this.$moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        completed_at: null,
        deleted_at: null
      };
      return {
        ...base,
        ...this.inputSchema(schema, base)
      };
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'ApplicationFooter',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_PanelLeftIcon = _resolveComponent("PanelLeftIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_HomeIcon = _resolveComponent("HomeIcon");
      const _component_SearchIcon = _resolveComponent("SearchIcon");
      const _component_PlusIcon = _resolveComponent("PlusIcon");
      const _component_EntryDisplay = _resolveComponent("EntryDisplay");
      const _component_MenuItem = _resolveComponent("MenuItem");
      const _component_MenuHelper = _resolveComponent("MenuHelper");
      const _directive_touch = _resolveDirective("touch");
      return _openBlock(), _createElementBlock("div", {
        id: "application-footer",
        class: _normalizeClass(_ctx.$style.applicationFooter)
      }, [_createElementVNode("div", {
        class: _normalizeClass([{
          'shadow-lg ring-1 ring-inset ring-neutral-100 dark:shadow-none dark:ring-neutral-700': _ctx.mainFrame && _ctx.mainFrame.scrollHeight - _ctx.$window.innerHeight - __props.scrollY > 15
        }, "pointer-events-auto max-w-fit -translate-x-2 rounded-full bg-white/75 backdrop-blur transition-all dark:bg-black/75"])
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ButtonComponent, {
        ref: "left_sidebar_toggle_mobile",
        id: "left_sidebar_toggle_mobile",
        variant: "round",
        color: "secondary",
        size: "lg",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('toggleSidebarMobile'))
      }, {
        default: _withCtx(() => [_cache[4] || (_cache[4] = _createElementVNode("span", {
          class: "sr-only"
        }, "Open sidebar", -1)), _createVNode(_component_PanelLeftIcon, {
          size: "24"
        })]),
        _: 1
      }, 512), _cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200 dark:lg:bg-gray-800",
        "aria-hidden": "true"
      }, null, -1)), _ctx.anchors.length ? (_openBlock(), _createBlock(_component_ButtonComponent, {
        key: 0,
        variant: "round",
        color: "secondary",
        size: "lg",
        onClick: _cache[1] || (_cache[1] = () => {
          _ctx.$router.push({
            name: 'entry',
            params: {
              id: _ctx.anchors[0].id
            }
          });
        })
      }, {
        default: _withCtx(() => [_cache[5] || (_cache[5] = _createElementVNode("span", {
          class: "sr-only"
        }, "Go home", -1)), _createVNode(_component_HomeIcon, {
          size: "24"
        })]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_ButtonComponent, {
        onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('toggleCommandPalette')),
        variant: "round",
        color: "secondary",
        size: "lg"
      }, {
        default: _withCtx(() => [_createVNode(_component_SearchIcon, {
          size: "24"
        })]),
        _: 1
      })])], 2), _createVNode(_Transition, {
        "enter-active-class": "transition-all duration-[800ms] ease-spring",
        "enter-from-class": "opacity-0 translate-y-24",
        "enter-to-class": "opacity-100 translate-y-0",
        "leave-active-class": "transition-all duration-[800ms] ease-spring",
        "leave-to-class": "opacity-0 translate-y-24"
      }, {
        default: _withCtx(() => [__props.entry && _ctx.inputs.length ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([{
            'shadow-lg ring-1 ring-inset ring-neutral-100 dark:shadow-none dark:ring-neutral-700': _ctx.mainFrame && _ctx.mainFrame.scrollHeight - _ctx.$window.innerHeight - __props.scrollY > 15
          }, "pointer-events-auto max-w-fit translate-x-2 rounded-full bg-white/75 backdrop-blur transition-all dark:bg-black/75"])
        }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MenuHelper, {
          placement: "top-start"
        }, {
          button: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_withDirectives((_openBlock(), _createBlock(_component_ButtonComponent, {
            onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
            onMouseenter: _ctx.onMouseEnter,
            onMouseleave: _ctx.onMouseLeave,
            onMousedown: _ctx.onMouseDown,
            onMouseup: _ctx.onMouseUp,
            variant: "round",
            class: "pointer-events-auto !bg-transparent !p-0",
            title: `Add Entry to ${_ctx.inputs[0].entry.name || 'Unnamed'}`,
            shortcode: "+"
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              ref: "button",
              class: _normalizeClass([_ctx.$style.addButton, "h-full w-full rounded-full p-2 transition-colors duration-500 dark:ring-1 dark:ring-neutral-700"]),
              style: _normalizeStyle({
                '--ps-color': __props.entry.color ? _ctx.$colors[__props.entry.color][500] : _ctx.$colors.neutral[900]
              })
            }, [_createVNode(_component_PlusIcon, {
              size: "24"
            })], 6)]),
            _: 1
          }, 8, ["onMouseenter", "onMouseleave", "onMousedown", "onMouseup", "title"])), [[_directive_touch, _ctx.onMouseDown, "press", {
            stop: true
          }], [_directive_touch, _ctx.onMouseUp, "release", {
            stop: true
          }]])], 512)]),
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputs.slice(0, _ctx.inputs.length), (input, key) => {
            return _openBlock(), _createBlock(_component_MenuItem, {
              key: key
            }, {
              default: _withCtx(({
                active
              }) => [_createElementVNode("a", {
                onClick: _withModifiers($event => _ctx.focusInput(input), ["stop"]),
                active: active,
                class: _normalizeClass(_ctx.$style.menuItem)
              }, [_createVNode(_component_EntryDisplay, {
                modelValue: _ctx.inputSchemaWithId(input.schema, input.entry.name),
                disabled: true,
                size: "xs"
              }, null, 8, ["modelValue"])], 10, _hoisted_4)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        })])], 2)) : _createCommentVNode("", true)]),
        _: 1
      })], 2);
    };
  }
});