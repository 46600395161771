import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex-shrink-0"
};
const _hoisted_2 = {
  class: "flex-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PanelRightCloseIcon = _resolveComponent("PanelRightCloseIcon");
  const _component_PanelRightOpenIcon = _resolveComponent("PanelRightOpenIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_OutputDisplay = _resolveComponent("OutputDisplay");
  return _openBlock(), _createElementBlock("div", {
    id: "right-sidebar",
    class: _normalizeClass(_ctx.$style.leftSidebar),
    style: _normalizeStyle($options.cssProps)
  }, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.leftSidebarTopOuter)
  }, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.$style.leftSidebarTopInner, "flex flex-1 items-center justify-between overflow-visible"])
  }, [_cache[5] || (_cache[5] = _createElementVNode("div", {
    class: "flex-1 truncate"
  }, [_createElementVNode("span", {
    class: "text-neutral-900 dark:text-neutral-200"
  })], -1)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_ButtonComponentNew, {
    class: _normalizeClass(_ctx.$style.buttonSpaceSettings),
    title: "Toggle sidebar",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', !$props.modelValue))
  }, {
    default: _withCtx(() => [_cache[4] || (_cache[4] = _createElementVNode("span", {
      class: "sr-only"
    }, "Toggle sidebar", -1)), $props.modelValue ? (_openBlock(), _createBlock(_component_PanelRightCloseIcon, {
      key: 0,
      size: "20",
      strokeWidth: "1.5"
    })) : (_openBlock(), _createBlock(_component_PanelRightOpenIcon, {
      key: 1,
      size: "20",
      strokeWidth: "1.5"
    }))]),
    _: 1
  }, 8, ["class"])])], 2)], 2), _createElementVNode("div", _hoisted_2, [_createVNode(_component_OutputDisplay, {
    id: $props.outputId,
    modelValue: $data.originsEntry.output,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.originsEntry.output = $event),
    entry: $data.originsEntry,
    display: {
      status: false,
      procrastination: false,
      links: false,
      description: false,
      schedule: false,
      output: true,
      output_count: true,
      input: false,
      columns: false,
      leftover: false,
      settings: true,
      senses: true,
      routine: false,
      time_trackings: false,
      custom_fields: false
    },
    overrideEntryPermissions: {
      name: false,
      description: false,
      status: false
    },
    sorting: $data.originsEntry.output.sorting,
    position: "left",
    disableViewportChecking: false,
    entriesDisabled: true,
    onOnEntryClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('onEntryClick')),
    editable: false,
    size: "sm",
    onCreated: _cache[3] || (_cache[3] = entry => {
      _ctx.$document.activeElement?.blur();
      _ctx.$nextTick(() => {
        this.$router.push({
          name: 'entry',
          params: {
            id: entry.id
          }
        });
      });
    }),
    overrideOnDrop: $options.handleAnchorSorting,
    class: _normalizeClass(_ctx.$style.outputDisplay)
  }, null, 8, ["id", "modelValue", "entry", "sorting", "overrideOnDrop", "class"])])], 6);
}