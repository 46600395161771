import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = ["onClick", "onMousedown", "onMouseup"];
const _hoisted_2 = {
  key: 1,
  class: "inline-block"
};
const _hoisted_3 = {
  key: 2,
  class: "inline-block"
};
const _hoisted_4 = {
  key: 1,
  class: "flex gap-x-2.5"
};
const _hoisted_5 = {
  class: "!ml-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NativeTransitionGroupHelper = _resolveComponent("NativeTransitionGroupHelper");
  const _component_OutputCount = _resolveComponent("OutputCount");
  const _component_TimeTrackingsToday = _resolveComponent("TimeTrackingsToday");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.entryDisplayDetail)
  }, [_renderSlot(_ctx.$slots, "cover"), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.topRow)
  }, [_renderSlot(_ctx.$slots, "name"), _renderSlot(_ctx.$slots, "settings"), _renderSlot(_ctx.$slots, "top-right")], 2), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.description)
  }, [_renderSlot(_ctx.$slots, "description")], 2), $options.existingAttributes.length ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.entryAttributes)
  }, [_createVNode(_component_NativeTransitionGroupHelper, {
    "enter-active-class": "transition-all duration-500 ease-spring",
    "enter-from-class": "opacity-0 translate-y-5",
    "enter-to-class": "opacity-100 translate-y-0",
    "leave-active-class": "transition-all duration-500 absolute ease-spring pointer-events-none",
    "leave-from-class": "opacity-100 translate-y-0",
    "leave-to-class": "opacity-0 translate-y-5"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.existingAttributes, attribute => {
      return _openBlock(), _createElementBlock("div", {
        key: attribute,
        class: _normalizeClass([_ctx.$style.entryAttribute, _ctx.$style[attribute]]),
        ref_for: true,
        ref: attribute,
        role: "button",
        onClick: e => $options.handleMouseEvent(e, attribute, 'click'),
        onMousedown: _withModifiers(e => $options.handleMouseEvent(e, attribute, 'mousedown'), ["stop"]),
        onMouseup: _withModifiers(e => $options.handleMouseEvent(e, attribute, 'mouseup'), ["stop"])
      }, [_createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.attributeName)
      }, [$options.getAttributeIcon(attribute) ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.getAttributeIcon(attribute)), {
        key: 0,
        size: "14",
        strokeWidth: "1.5",
        class: "inline-block h-[14px] w-[14px]"
      })) : _createCommentVNode("", true), !attribute.startsWith('custom_field_') ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(attribute.split("_").join(" ")), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($options.customFieldsComputed.flatMap(e => e.custom_fields).find(e => e.id == attribute.split("_").slice(2).join("_"))?.name), 1))], 2), _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.attributeValue)
      }, [_renderSlot(_ctx.$slots, attribute, {
        internalSettings: {
          display: {
            label: true,
            settings: true
          }
        }
      })], 2)], 42, _hoisted_1);
    }), 128))]),
    _: 3
  })], 2)) : _createCommentVNode("", true), 3 > 4 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_ctx.$slots.before ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass([_ctx.$style.alignHeight])
  }, [_renderSlot(_ctx.$slots, "before")], 2)) : _createCommentVNode("", true), true /* && isLazyMounted*/ ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass({
      [_ctx.$style.leftColumn]: true
      // 'pt-0.5': ['sm', 'md'].includes(size), // in case name is bigger
      // 'pt-1': ['lg', 'xl', '2xl'].includes(size), // in case name is bigger
      // 'pt-3': ['4xl'].includes(size), // in case name is bigger
    })
  }, [$props.computedDisplay.output &&
  // !['gallery'].includes(renderType) &&
  $props.entry.output ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.renderOutput)
  }, [_renderSlot(_ctx.$slots, "toggleOutput")], 2)) : _createCommentVNode("", true), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.statusAndSensesWrapper)
  }, null, 2)], 2)) : _createCommentVNode("", true), _createElementVNode("div", {
    key: "center",
    class: _normalizeClass(_ctx.$style.centerColumn)
  }, [_createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.centerColumn_firstRow)
  }, [_renderSlot(_ctx.$slots, "name")], 2), _renderSlot(_ctx.$slots, "description"), $props.computedDisplay.schedule && ($props.entry.schedule || _ctx.$slots.schedule) || $props.computedDisplay.time_trackings && ($props.entry.time_trackings?.length || _ctx.$slots.time_trackings) || $props.computedDisplay.routine && ($props.entry.routine || _ctx.$slots.routine) ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(_ctx.$style.centerColumn_thirdRow)
  }, [_renderSlot(_ctx.$slots, "schedule"), _renderSlot(_ctx.$slots, "time_trackings"), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "routine")])], 2)) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "custom_fields")], 2), _createElementVNode("div", {
    class: _normalizeClass(_ctx.$style.rightColumn)
  }, [_renderSlot(_ctx.$slots, "top-right"), $props.computedDisplay.output_count && $props.entry.output ? (_openBlock(), _createBlock(_component_OutputCount, {
    key: 0,
    entry: $props.entry,
    class: "mt-[2px] w-5 text-center text-sm text-neutral-500 group-hover:opacity-0"
  }, null, 8, ["entry"])) : _createCommentVNode("", true), $props.computedDisplay.time_trackings_today && $props.entry.time_trackings ? (_openBlock(), _createBlock(_component_TimeTrackingsToday, {
    key: 1,
    modelValue: $props.entry.time_trackings,
    entry: $props.entry,
    class: "self-center text-center text-sm text-neutral-500"
  }, null, 8, ["modelValue", "entry"])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "settings")], 2)])) : _createCommentVNode("", true)], 2);
}