import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "uppercase"
};
const _hoisted_2 = {
  class: "min-w-fit overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-black dark:ring-neutral-700"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  style: {
    "display": "none"
  }
};
const _hoisted_5 = {
  style: {
    "color": "var(--color-medium-gray)"
  }
};
const _hoisted_6 = {
  style: {
    "color": "var(--color-medium-gray)"
  }
};
const _hoisted_7 = {
  class: "px-3 py-1",
  style: {
    "display": "flex",
    "gap": "1.5rem",
    "justify-content": "space-between"
  }
};
const _hoisted_8 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_9 = {
  style: {
    "font-weight": "bold",
    "min-width": "1.75rem",
    "text-align": "center"
  }
};
const _hoisted_10 = {
  style: {
    "display": "flex",
    "gap": "0.5rem",
    "color": "var(--color-medium-dark-gray)"
  }
};
const _hoisted_11 = {
  style: {
    "font-weight": "bold",
    "min-width": "1.75rem",
    "text-align": "center"
  }
};
const _hoisted_12 = {
  style: {
    "display": "none",
    "gap": "0.5rem",
    "color": "var(--color-medium-gray)"
  }
};
const _hoisted_13 = {
  style: {
    "font-weight": "bold",
    "min-width": "1.75rem",
    "text-align": "center"
  }
};
const _hoisted_14 = {
  key: 0
};
const _hoisted_15 = {
  key: 1,
  style: {
    "display": "flex",
    "flex-direction": "column",
    "gap": "0.5rem",
    "max-height": "100px",
    "overflow-y": "auto",
    "scrollbar-gutter": "stable"
  }
};
const _hoisted_16 = {
  style: {
    "flex-grow": "1",
    "font-size": "var(--font-size-smaller)",
    "white-space": "nowrap",
    "text-overflow": "ellipsis",
    "max-width": "8rem",
    "overflow": "hidden",
    "display": "inline-block"
  }
};
const _hoisted_17 = {
  class: "px-3 py-1",
  style: {
    "display": "flex",
    "flex-direction": "column",
    "gap": "0.5rem",
    "max-height": "100px",
    "overflow-y": "auto",
    "scrollbar-gutter": "stable"
  }
};
const _hoisted_18 = {
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_19 = {
  key: 0,
  style: {
    "color": "var(--color-medium-gray)"
  }
};
const _hoisted_20 = {
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_21 = {
  key: 0,
  style: {
    "color": "var(--color-medium-gray)"
  }
};
const _hoisted_22 = {
  key: 1
};
const _hoisted_23 = {
  class: "justfy-between flex gap-4 px-4 py-3"
};
const _hoisted_24 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_25 = {
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_26 = {
  style: {
    "display": "flex",
    "gap": "0.5rem"
  }
};
const _hoisted_27 = {
  style: {
    "font-weight": "bold",
    "min-width": "5rem",
    "display": "inline-block",
    "text-align": "center",
    "text-transform": "uppercase"
  }
};
import { Popover, PopoverButton, PopoverPanel, PopoverOverlay } from "@headlessui/vue";
import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import moment from "moment";
import { Scheduling } from "../mixins/Scheduling";
import { EditUtilities } from "../mixins/EditUtilities";
const __default__ = {
  mixins: [Scheduling, EditUtilities],
  props: {
    entry: Object,
    otherentries: Array,
    teleport: Boolean
  },
  data() {
    return {
      moment: moment,
      now: null,
      cursor: typeof this.modelValue == "object" ? JSON.parse(JSON.stringify(this.modelValue)) : this.modelValue
    };
  },
  watch: {
    "entry.schedule.time": function (n) {
      this.value = n;
      this.cursor = n;
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.now = moment.utc();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  computed: {
    duration: {
      get() {
        return this.entry?.schedule?.duration;
      },
      set(value) {
        this.$emit("update:duration", value);
      }
    },
    displayText() {
      if (this.value && this.duration) return this.valToHtml(this.value) + " - " + this.valToHtml(this.value, "HH:mm", {
        key: "seconds",
        val: this.duration
      });
      if (!this.value && this.duration) return this.duration / 60 + " min.";
      if (this.value || !this.duration) return this.valToHtml(this.value);
      return "Set Time";
    },
    date() {
      return moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    timePart(part, time) {
      if (time == null) return "--";
      switch (part) {
        case "H":
          return moment.utc(this.date + " " + time).local().format("HH");
        case "M":
          return moment.utc(this.date + " " + time).local().format("mm");
        case "S":
          return moment.utc(this.date + " " + time).local().format("ss");
      }
    },
    modifyRelativeDate(dir, time) {
      switch (dir) {
        case 1:
          switch (time.op) {
            case "now":
              time.op = "nextXHours";
              time.x = 1;
              break;
            default:
              time.x = typeof time.x != "undefined" ? time.op.slice(0, 4) == "next" ? time.x + 1 : time.x - 1 : 0;
              if (time.x < 0) {
                time.op = time.op.slice(0, 4) == "next" ? "last" + time.op.slice(4) : "next" + time.op.slice(4);
                time.x = 1;
              }
          }
          break;
        case -1:
          switch (time.op) {
            case "now":
              time.op = "lastXHours";
              time.x = 1;
              break;
            default:
              time.x = typeof time.x != "undefined" ? time.op.slice(0, 4) == "next" ? time.x - 1 : time.x + 1 : 0;
              if (time.x < 0) {
                time.op = time.op.slice(0, 4) == "next" ? "last" + time.op.slice(4) : "next" + time.op.slice(4);
                time.x = 1;
              }
          }
          break;
      }
      if ((time.op == "nextXHours" || time.op == "lastXHours") && time.x == 0) {
        delete time.x;
        time.op = "now";
      }
      if (time.op == "nextXHours" && time.x >= 24) {
        time.x = 0;
      }
      if (time.op == "lastXHours" && time.x >= 24) {
        time.x = 0;
      }
      if (time.op == "nextXMinutes" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "lastXMinutes" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "nextXSeconds" && time.x >= 60) {
        time.x = 0;
      }
      if (time.op == "lastXSeconds" && time.x >= 60) {
        time.x = 0;
      }
      //this.$emit("update", this.value);
    },
    modifyRelativeTimespan(dir, time) {
      if (time !== null) {
        switch (dir) {
          case 1:
            switch (time.op) {
              case "nextXSeconds":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "nextXMinutes":
                time.op = "nextXHours";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXSeconds":
                time.op = "lastXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXMinutes":
                time.op = "lastXHours";
                if (typeof time.x == "undefined") time.x = 1;
                break;
            }
            break;
          case -1:
            switch (time.op) {
              case "now":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 0;
                break;
              case "nextXMinutes":
                time.op = "nextXSeconds";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "nextXHours":
                time.op = "nextXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXMinutes":
                time.op = "lastXSeconds";
                if (typeof time.x == "undefined") time.x = 1;
                break;
              case "lastXHours":
                time.op = "lastXMinutes";
                if (typeof time.x == "undefined") time.x = 1;
                break;
            }
            break;
        }
        if ((time.op == "nextXHours" || time.op == "lastXHours") && time.x == 0) {
          delete time.x;
          time.op = "now";
        }
      }
      //this.$emit("update", this.value);
    },
    relativeTimespan(time) {
      if (time === null) {
        return [0, "hours"];
      }
      switch (time.op) {
        case "now":
          return [0, "hours"];
        case "lastXHours":
        case "nextXHours":
          return [time.x, "hour" + (time.x != 1 ? "s" : "")];
        case "lastXMinutes":
        case "nextXMinutes":
          return [time.x, "minute" + (time.x != 1 ? "s" : "")];
        case "lastXSeconds":
        case "nextXSeconds":
          return [time.x, "second" + (time.x != 1 ? "s" : "")];
      }
    },
    valToHtml(val, format = "HH:mm", mod) {
      if (val) {
        if (val.op == "null") return "unset time";
        if (val.op == "now") {
          return "now";
        }
        if (val.op == "nextXHours") {
          if (val.x == 0) return "now";
          return "in " + val.x + " hour" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "nextXMinutes") {
          if (val.x == 0) return "now";
          return "in " + val.x + " minute" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "nextXSeconds") {
          if (val.x == 0) return "now";
          return "in " + val.x + " second" + (val.x != 1 ? "s" : "");
        }
        if (val.op == "lastXHours") {
          if (val.x == 0) return "now";
          return val.x + " hour" + (val.x != 1 ? "s" : "") + " ago";
        }
        if (val.op == "lastXMinutes") {
          if (val.x == 0) return "now";
          return val.x + " minute" + (val.x != 1 ? "s" : "") + " ago";
        }
        if (val.op == "lastXSeconds") {
          if (val.x == 0) return "now";
          return val.x + " second" + (val.x != 1 ? "s" : "") + " ago";
        }
        /*
                @note this shows in completedAt Component: "TODAY now" - I don't like that.
                if (format == "HH:mm" && val == moment().utc().format("HH:mm:ss")) {
                  return "now";
                }*/
        var datetime = moment.utc(moment.utc().format("YYYY-MM-DD " + val));
        if (datetime.format("ss") != "00") format = format + ":ss";
        if (mod) {
          datetime = datetime.add(mod.val, mod.key);
        }
        return datetime.local().format(format);
      }
      return;
      // return "--:--";
    }
  }
};
export default /*@__PURE__*/Object.assign(__default__, {
  __name: 'TimePickerBuilder',
  setup(__props) {
    const reference = ref(null);
    const floating = ref(null);
    const {
      floatingStyles
    } = useFloating(reference, floating, {
      placement: "top-start",
      middleware: [flip(), shift({
        crossAxis: true
      })]
    });
    return (_ctx, _cache) => {
      const _component_ClockIcon = _resolveComponent("ClockIcon");
      const _component_ButtonComponent = _resolveComponent("ButtonComponent");
      const _component_TrashIcon = _resolveComponent("TrashIcon");
      const _component_XIcon = _resolveComponent("XIcon");
      const _component_PopoverHelper = _resolveComponent("PopoverHelper");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_PopoverHelper, {
        id: "time_picker",
        teleport: __props.teleport,
        class: "relative"
      }, {
        button: _withCtx(() => [_createVNode(_component_ButtonComponent, {
          onClick: _cache[0] || (_cache[0] = e => {
            __props.entry ? typeof _ctx.value != 'string' ? _ctx.cursor = _unref(moment).utc().add(1, 'hours').format('HH:00:00') : null : typeof _ctx.value == 'undefined' || _ctx.value === null ? _ctx.value = {
              op: 'now'
            } : null;
            // $emit('click', e);
          }),
          variant: "none",
          ref_key: "reference",
          ref: reference,
          class: "group inline-flex flex-wrap items-center gap-x-1 text-left"
        }, {
          default: _withCtx(() => [_createVNode(_component_ClockIcon, {
            size: "14"
          }), _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.displayText), 1)]),
          _: 1
        }, 512)]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [typeof _ctx.cursor != 'object' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.now), 1), _createVNode(_unref(PopoverButton), {
          onClick: _cache[1] || (_cache[1] = $event => _ctx.value = _ctx.interpretTime({
            time: _unref(moment)().utc().format('HH:mm:00')
          })),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[17] || (_cache[17] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Now ", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.interpretTime({
            time: _unref(moment)().format("HH:mm")
          })), 1)]),
          _: 1
        }), _createVNode(_unref(PopoverButton), {
          onClick: _cache[2] || (_cache[2] = $event => _ctx.value = _ctx.interpretTime({
            time: _unref(moment)().utc().add(1, 'hour').format('HH:mm:00')
          })),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[18] || (_cache[18] = _createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " In 1 Hour ", -1)), _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.interpretTime({
            time: _unref(moment)().add(1, "hour").format("HH:mm")
          })), 1)]),
          _: 1
        }), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_ButtonComponent, {
          onClick: _cache[3] || (_cache[3] = _withModifiers($event => _ctx.cursor = _unref(moment)(_ctx.date + ' ' + _ctx.cursor).add(-1, 'hours').format('HH:mm:ss'), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[19] || (_cache[19] = [_createTextVNode(" − ")])),
          _: 1
        }), _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(moment).utc(_ctx.date + " " + _ctx.cursor).local().format("HH")), 1), _createVNode(_component_ButtonComponent, {
          onClick: _cache[4] || (_cache[4] = _withModifiers($event => _ctx.cursor = _unref(moment)(_ctx.date + ' ' + _ctx.cursor).add(1, 'hours').format('HH:mm:ss'), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[20] || (_cache[20] = [_createTextVNode(" + ")])),
          _: 1
        })]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_ButtonComponent, {
          onClick: _cache[5] || (_cache[5] = _withModifiers($event => _ctx.cursor = _unref(moment)(_ctx.date + ' ' + _ctx.cursor).add(-5, 'minutes').format('HH:mm:ss'), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[21] || (_cache[21] = [_createTextVNode(" − ")])),
          _: 1
        }), _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(moment).utc(_ctx.date + " " + _ctx.cursor).local().format("mm")), 1), _createVNode(_component_ButtonComponent, {
          onClick: _cache[6] || (_cache[6] = _withModifiers($event => _ctx.value = _unref(moment)(_ctx.date + ' ' + _ctx.cursor).add(5, 'minutes').format('HH:mm:ss'), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[22] || (_cache[22] = [_createTextVNode(" + ")])),
          _: 1
        })]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_ButtonComponent, {
          onClick: _cache[7] || (_cache[7] = _withModifiers($event => _ctx.cursor = _unref(moment)(_ctx.date + ' ' + _ctx.cursor).add(-15, 'seconds').format('HH:mm:ss'), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[23] || (_cache[23] = [_createTextVNode(" − ")])),
          _: 1
        }), _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(moment).utc(_ctx.date + " " + _ctx.cursor).local().format("ss")), 1), _createVNode(_component_ButtonComponent, {
          onClick: _cache[8] || (_cache[8] = _withModifiers($event => _ctx.cursor = _unref(moment)(_ctx.date + ' ' + _ctx.cursor).add(15, 'seconds').format('HH:mm:ss'), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[24] || (_cache[24] = [_createTextVNode(" + ")])),
          _: 1
        })])]), _ctx.cursor != _ctx.value ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_unref(PopoverButton), {
          onClick: _cache[9] || (_cache[9] = $event => _ctx.value = _ctx.cursor),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => _cache[25] || (_cache[25] = [_createTextVNode(" Save ")])),
          _: 1
        })])) : _createCommentVNode("", true), __props.otherentries ? (_openBlock(), _createElementBlock("div", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.otherentries?.filter(e => e.schedule?.time), (otherentry, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            style: {
              "display": "flex",
              "justify-content": "space-between",
              "gap": "1rem",
              "align-items": "center"
            }
          }, [_createVNode(_component_ButtonComponent, {
            style: {
              "background": "var(--color-dark-gray)",
              "padding": "0.25rem 0.5rem",
              "border-radius": "4px",
              "color": "var(--color-white)"
            },
            onClick: $event => (_ctx.value = _unref(moment).utc(otherentry.schedule.date + ' ' + otherentry.schedule.time).add(-__props.entry.schedule.duration, 'seconds').format('HH:mm:ss'), _ctx.edit = false),
            title: _unref(moment).utc(otherentry.schedule.date + ' ' + otherentry.schedule.time).add(-__props.entry.schedule.duration, 'seconds').local().format('HH:mm:ss')
          }, {
            default: _withCtx(() => _cache[26] || (_cache[26] = [_createTextVNode(" − ")])),
            _: 2
          }, 1032, ["onClick", "title"]), _createElementVNode("span", _hoisted_16, _toDisplayString(otherentry.name), 1), _createVNode(_component_ButtonComponent, {
            style: {
              "background": "var(--color-dark-gray)",
              "padding": "0.25rem 0.5rem",
              "border-radius": "4px",
              "color": "var(--color-white)"
            },
            onClick: $event => (_ctx.value = _unref(moment).utc(otherentry.schedule.date + ' ' + otherentry.schedule.time).add(otherentry.schedule.duration, 'seconds').format('HH:mm:ss'), _ctx.edit = false),
            title: _unref(moment).utc(otherentry.schedule.date + ' ' + otherentry.schedule.time).add(otherentry.schedule.duration, 'seconds').local().format('HH:mm:ss')
          }, {
            default: _withCtx(() => _cache[27] || (_cache[27] = [_createTextVNode(" + ")])),
            _: 2
          }, 1032, ["onClick", "title"])]);
        }), 128))])) : _createCommentVNode("", true), _createVNode(_unref(PopoverButton), {
          onClick: _cache[10] || (_cache[10] = $event => _ctx.value = null),
          class: "flex w-full cursor-pointer items-center justify-between gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_cache[28] || (_cache[28] = _createElementVNode("span", null, " Unset ", -1)), _createVNode(_component_TrashIcon, {
            class: "h-5 w-5"
          })]),
          _: 1
        }), _cache[30] || (_cache[30] = _createElementVNode("small", {
          class: "px-3 py-1",
          style: {
            "display": "block",
            "text-align": "center"
          }
        }, " Duration ", -1)), _createElementVNode("div", _hoisted_17, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, i => {
          return _createVNode(_unref(PopoverButton), {
            key: i,
            onClick: $event => (_ctx.edit = false, _ctx.duration = 60 * (5 * i), _ctx.$emit('update:duration', _ctx.duration)),
            style: {
              "display": "flex",
              "justify-content": "space-between"
            }
          }, {
            default: _withCtx(() => [_createElementVNode("span", _hoisted_18, _toDisplayString(5 * i) + " Min. ", 1), _ctx.value ? (_openBlock(), _createElementBlock("span", _hoisted_19, " → " + _toDisplayString(_ctx.valToHtml(_ctx.value, "HH:mm", {
              key: "seconds",
              val: 5 * i * 60
            })), 1)) : _createCommentVNode("", true)]),
            _: 2
          }, 1032, ["onClick"]);
        }), 64)), (_openBlock(), _createElementBlock(_Fragment, null, _renderList(28, i => {
          return _createVNode(_unref(PopoverButton), {
            key: i,
            onClick: $event => (_ctx.edit = false, _ctx.duration = 60 * 60 + 60 * (15 * i), _ctx.$emit('update:duration', _ctx.duration)),
            style: {
              "display": "flex",
              "justify-content": "space-between"
            }
          }, {
            default: _withCtx(() => [_createElementVNode("span", _hoisted_20, _toDisplayString(1 + i / 4) + " Hrs. ", 1), _ctx.value ? (_openBlock(), _createElementBlock("span", _hoisted_21, " → " + _toDisplayString(_ctx.valToHtml(_ctx.value, "HH:mm", {
              key: "seconds",
              val: 60 * 60 + 15 * i * 60
            })), 1)) : _createCommentVNode("", true)]),
            _: 2
          }, 1032, ["onClick"]);
        }), 64))]), _ctx.duration ? (_openBlock(), _createBlock(_unref(PopoverButton), {
          key: 2,
          onClick: _cache[11] || (_cache[11] = $event => (_ctx.edit = false, _ctx.duration = null, _ctx.$emit('update:duration', _ctx.duration))),
          style: {
            "display": "flex",
            "justify-content": "space-between"
          }
        }, {
          default: _withCtx(() => _cache[29] || (_cache[29] = [_createElementVNode("span", {
            style: {
              "font-weight": "bold"
            }
          }, " Unset ", -1), _createElementVNode("span", {
            style: {
              "color": "var(--color-medium-gray)"
            }
          }, null, -1)])),
          _: 1
        })) : _createCommentVNode("", true)])) : _createCommentVNode("", true), typeof _ctx.cursor == 'object' ? (_openBlock(), _createElementBlock("div", _hoisted_22, [_createElementVNode("div", _hoisted_23, [_ctx.value?.op ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          style: _normalizeStyle([{
            color: _ctx.value.op == 'now' || _ctx.value.op.slice(0, 4) == 'next' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x >= 0) || _ctx.value.op.slice(0, 4) == 'last' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x <= 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
          }, {
            "font-size": "82%",
            "font-weight": "bold",
            "text-transform": "uppercase"
          }])
        }, " in ", 4)) : _createCommentVNode("", true), _createElementVNode("span", null, [_createElementVNode("span", _hoisted_24, [_createVNode(_component_ButtonComponent, {
          onClick: _cache[12] || (_cache[12] = _withModifiers($event => _ctx.modifyRelativeDate(-1, _ctx.value), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[31] || (_cache[31] = [_createTextVNode(" − ")])),
          _: 1
        }), _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.relativeTimespan(_ctx.value) ? Math.abs(_ctx.relativeTimespan(_ctx.value)[0]) : null), 1), _createVNode(_component_ButtonComponent, {
          onClick: _cache[13] || (_cache[13] = _withModifiers($event => _ctx.modifyRelativeDate(1, _ctx.value), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[32] || (_cache[32] = [_createTextVNode(" + ")])),
          _: 1
        })])]), _createElementVNode("span", null, [_createElementVNode("span", _hoisted_26, [_createVNode(_component_ButtonComponent, {
          onClick: _cache[14] || (_cache[14] = _withModifiers($event => _ctx.modifyRelativeTimespan(-1, _ctx.value), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[33] || (_cache[33] = [_createTextVNode(" − ")])),
          _: 1
        }), _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.relativeTimespan(_ctx.value) ? _ctx.relativeTimespan(_ctx.value)[1] : null), 1), _createVNode(_component_ButtonComponent, {
          onClick: _cache[15] || (_cache[15] = _withModifiers($event => _ctx.modifyRelativeTimespan(1, _ctx.value), ["prevent"]))
        }, {
          default: _withCtx(() => _cache[34] || (_cache[34] = [_createTextVNode(" + ")])),
          _: 1
        })])]), _ctx.value?.op ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          style: _normalizeStyle([{
            color: _ctx.value.op.slice(0, 4) == 'next' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x < 0) || _ctx.value.op.slice(0, 4) == 'last' && (typeof _ctx.value.x == 'undefined' || _ctx.value.x > 0) ? 'var(--color-dark-gray)' : 'var(--color-medium-gray)'
          }, {
            "font-size": "82%",
            "font-weight": "bold",
            "text-transform": "uppercase"
          }])
        }, " ago ", 4)) : _createCommentVNode("", true)]), _createVNode(_unref(PopoverButton), {
          onClick: _cache[16] || (_cache[16] = $event => [_ctx.value = __props.entry ? {
            op: 'null'
          } : {
            op: 'unset'
          }]),
          class: "flex w-full cursor-pointer items-center gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-950"
        }, {
          default: _withCtx(() => [_createVNode(_component_XIcon, {
            size: "16"
          }), _cache[35] || (_cache[35] = _createTextVNode(" Unset "))]),
          _: 1
        })])) : _createCommentVNode("", true)])]),
        _: 1
      }, 8, ["teleport"])]);
    };
  }
});