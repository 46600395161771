import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "relative flex w-64 flex-col gap-8 p-5"
};
const _hoisted_2 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
};
const _hoisted_3 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
};
const _hoisted_4 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
};
const _hoisted_5 = {
  class: "flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
};
const _hoisted_6 = {
  key: 0,
  class: "bg-gray-50 p-4"
};
const _hoisted_7 = {
  class: "flex items-center"
};
const _hoisted_8 = {
  class: "text-sm font-medium text-gray-900"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LayersIcon = _resolveComponent("LayersIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_ListIcon = _resolveComponent("ListIcon");
  const _component_PopoverButton = _resolveComponent("PopoverButton");
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon");
  const _component_CalendarDaysIcon = _resolveComponent("CalendarDaysIcon");
  const _component_PopoverHelper = _resolveComponent("PopoverHelper");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_PopoverHelper, null, {
    button: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
      class: _normalizeClass(_ctx.$style.settingsButton),
      title: "Group Output"
    }, {
      default: _withCtx(() => [_createVNode(_component_LayersIcon, {
        size: "16",
        strokeWidth: "1.5"
      })]),
      _: 1
    }, 8, ["class"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('update:modelValue', null)),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_ListIcon, {
        size: "20"
      })]), _cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
      }, " None "), _createElementVNode("p", {
        class: "text-sm text-gray-500 dark:text-neutral-400"
      }, " No grouping, entries are rendered as they come ")], -1))]),
      _: 1
    }), _createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('update:modelValue', {
        field: 'status',
        dir: 1
      })),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_CheckCircleIcon, {
        class: "h-5 w-5"
      })]), _cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
      }, " Status "), _createElementVNode("p", {
        class: "text-sm text-gray-500 dark:text-neutral-400"
      }, " Entries are grouped by status ")], -1))]),
      _: 1
    }), _createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('update:modelValue', {
        field: 'schedule',
        dir: 1
      })),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_CalendarDaysIcon, {
        class: "h-5 w-5"
      })]), _cache[7] || (_cache[7] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
      }, " Schedule · Date "), _createElementVNode("p", {
        class: "text-sm text-gray-500 dark:text-neutral-400"
      }, " Entries are grouped by schedule date ")], -1))]),
      _: 1
    }), _createVNode(_component_PopoverButton, {
      as: "a",
      onClick: _cache[3] || (_cache[3] = $event => _ctx.$emit('update:modelValue', {
        field: 'schedule_time',
        dir: 1
      })),
      class: "-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_CalendarDaysIcon, {
        class: "h-5 w-5"
      })]), _cache[8] || (_cache[8] = _createElementVNode("div", {
        class: "ml-4"
      }, [_createElementVNode("p", {
        class: "text-sm font-medium text-gray-900 dark:text-neutral-300"
      }, " Schedule · Time "), _createElementVNode("p", {
        class: "text-sm text-gray-500 dark:text-neutral-400"
      }, " Entries are grouped by schedule time ")], -1))]),
      _: 1
    })]), _ctx.modelValue?.field == 'status' ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("a", {
      onClick: _cache[4] || (_cache[4] = $event => _ctx.$emit('update:modelValue', {
        field: _ctx.modelValue.field,
        dir: _ctx.modelValue.dir * -1
      })),
      class: "flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
    }, [_createElementVNode("span", _hoisted_7, [_createElementVNode("span", _hoisted_8, " Sort " + _toDisplayString(_ctx.modelValue.dir === 1 ? "Ascending" : "Descending"), 1)])])])) : _createCommentVNode("", true)]),
    _: 1
  })]);
}