import { defineAsyncComponent, hydrateOnIdle } from "vue";
export default {
  components: {
    OutputCount: defineAsyncComponent({
      loader: () => import("@/components/output/partials/OutputCount.vue"),
      hydrate: hydrateOnIdle
    }),
    TimeTrackingsToday: defineAsyncComponent({
      loader: () => import("@/components/timetrackings/partials/TimeTrackingsToday.vue"),
      hydrate: hydrateOnIdle
    })
  },
  props: {
    output: Object,
    entry: Object,
    computedDisplay: Object
  },
  computed: {
    isRenderLeftColumn() {
      return this.computedDisplay.output && /*!["gallery"].includes(this.renderType) && */
      this.entry.output || this.computedDisplay.status && this.entry.status_id /*this.renderType != "tiles" &&*/ || this.computedDisplay.senses && this.entry.senses?.length;
    }
  }
};