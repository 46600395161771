import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "flex flex-col gap-2 divide-y dark:divide-neutral-700"
};
const _hoisted_2 = {
  class: "pt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SelectMenu = _resolveComponent("SelectMenu");
  const _component_TrashIcon = _resolveComponent("TrashIcon");
  const _component_ButtonComponent = _resolveComponent("ButtonComponent");
  const _component_PlusIcon = _resolveComponent("PlusIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (s, k) => {
    return _openBlock(), _createElementBlock("fieldset", {
      key: k,
      class: "flex flex-wrap gap-2 pt-2 first:pt-0"
    }, [_createVNode(_component_SelectMenu, {
      modelValue: s.field,
      "onUpdate:modelValue": $event => s.field = $event,
      options: $data.fields,
      openWhenEmpty: true
    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]), _createVNode(_component_SelectMenu, {
      modelValue: s.dir,
      "onUpdate:modelValue": $event => s.dir = $event,
      options: [{
        title: 'Ascending',
        value: 1
      }, {
        title: 'Descending',
        value: -1
      }],
      openWhenEmpty: true
    }, null, 8, ["modelValue", "onUpdate:modelValue"]), _createVNode(_component_ButtonComponent, {
      onClick: $event => [_ctx.value.splice(k, 1)],
      color: "secondary"
    }, {
      default: _withCtx(() => [_createVNode(_component_TrashIcon, {
        class: "h-4 w-4"
      })]),
      _: 2
    }, 1032, ["onClick"])]);
  }), 128)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_ButtonComponent, {
    onClick: _withModifiers($options.addElement, ["stop"]),
    color: "secondary"
  }, {
    default: _withCtx(() => [_createVNode(_component_PlusIcon, {
      size: "16"
    }), _cache[0] || (_cache[0] = _createTextVNode(" Add "))]),
    _: 1
  }, 8, ["onClick"])])]);
}