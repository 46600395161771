import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "fixed inset-0 z-10 w-screen overflow-y-auto"
};
const _hoisted_2 = {
  class: "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
};
const _hoisted_3 = {
  class: "absolute right-0 top-0 hidden pr-4 pt-4 sm:block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TransitionChild = _resolveComponent("TransitionChild");
  const _component_XIcon = _resolveComponent("XIcon");
  const _component_DialogTitle = _resolveComponent("DialogTitle");
  const _component_DialogPanel = _resolveComponent("DialogPanel");
  const _component_Dialog = _resolveComponent("Dialog");
  const _component_TransitionRoot = _resolveComponent("TransitionRoot");
  return _openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createVNode(_component_TransitionRoot, {
    as: "template",
    show: $props.show
  }, {
    default: _withCtx(() => [_createVNode(_component_Dialog, {
      as: "div",
      class: "relative z-[999]",
      onClose: _cache[1] || (_cache[1] = $event => _ctx.$emit('close'))
    }, {
      default: _withCtx(() => [_createVNode(_component_TransitionChild, {
        as: "template",
        enter: "ease-out duration-300",
        "enter-from": "opacity-0",
        "enter-to": "opacity-100",
        leave: "ease-in duration-200",
        "leave-from": "opacity-100",
        "leave-to": "opacity-0"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("div", {
          class: "fixed inset-0 backdrop-blur-sm transition-opacity"
        }, null, -1)])),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_TransitionChild, {
        as: "template",
        enter: "ease-out duration-300",
        "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        "enter-to": "opacity-100 translate-y-0 sm:scale-100",
        leave: "ease-in duration-200",
        "leave-from": "opacity-100 translate-y-0 sm:scale-100",
        "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      }, {
        default: _withCtx(() => [_createVNode(_component_DialogPanel, {
          class: "relative w-full transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 md:max-w-xl dark:bg-black dark:shadow-none dark:ring-1 dark:ring-neutral-700"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("button", {
            type: "button",
            class: "rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-black dark:text-neutral-300 dark:ring-offset-black dark:hover:text-neutral-200 dark:focus:ring-neutral-500",
            onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('close'))
          }, [_cache[3] || (_cache[3] = _createElementVNode("span", {
            class: "sr-only"
          }, "Close", -1)), _createVNode(_component_XIcon, {
            size: "24",
            "aria-hidden": "true"
          })])]), _createVNode(_component_DialogTitle, {
            as: "h3",
            class: "mb-2 text-base font-semibold leading-6 text-gray-900 dark:text-neutral-300"
          }, {
            default: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
            _: 3
          }), _renderSlot(_ctx.$slots, "default")]),
          _: 3
        })]),
        _: 3
      })])])]),
      _: 3
    })]),
    _: 3
  }, 8, ["show"])]);
}