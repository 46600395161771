import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "flex w-full flex-wrap justify-between gap-1"
};
const _hoisted_2 = ["data-frequency"];
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 0,
  class: "absolute -right-2.5 -top-2.5 flex h-5 w-5 items-center justify-center rounded-full bg-neutral-500 text-xs font-bold"
};
const _hoisted_6 = {
  class: "inline-block text-xs font-bold"
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = {
  key: 0,
  class: "absolute -right-2.5 -top-2.5 flex h-5 w-5 items-center justify-center rounded-full bg-neutral-500 text-xs font-bold"
};
const _hoisted_9 = {
  class: "inline-block text-xs font-bold"
};
const _hoisted_10 = {
  key: 2
};
const _hoisted_11 = {
  class: "inline-block whitespace-nowrap text-xs font-bold"
};
const _hoisted_12 = {
  key: 3
};
const _hoisted_13 = {
  class: "inline-block text-xs font-bold"
};
const _hoisted_14 = {
  class: "pointer-events-none absolute -bottom-12 right-0 z-10 w-max items-center gap-2 truncate rounded-md bg-white px-2 py-1 text-sm opacity-0 drop-shadow-md group-hover/routine_log:flex group-hover/routine_log:opacity-100 group-hover/routine_log:transition-opacity group-hover/routine_log:delay-300 dark:bg-black dark:ring-1 dark:ring-neutral-700"
};
const _hoisted_15 = {
  class: "text-center text-xs text-black dark:text-neutral-300"
};
const _hoisted_16 = {
  key: 0,
  class: "flex items-center gap-1"
};
const _hoisted_17 = {
  key: 1,
  class: "flex items-center gap-1"
};
const _hoisted_18 = {
  key: 2,
  class: "flex items-center gap-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CircleHelpIcon = _resolveComponent("CircleHelpIcon");
  const _component_CircleCheckIcon = _resolveComponent("CircleCheckIcon");
  const _component_XCircleIcon = _resolveComponent("XCircleIcon");
  const _component_SettingsIcon = _resolveComponent("SettingsIcon");
  const _component_ButtonComponentNew = _resolveComponent("ButtonComponentNew");
  const _component_RoutineSettings = _resolveComponent("RoutineSettings");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.routineDisplay, $props.disabled ? 'pointer-events-none' : ''])
  }, [$options.routine ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "group/routine flex flex-1 grow items-center gap-1",
    "data-frequency": $options.routine.frequency,
    style: _normalizeStyle({
      '--ps-routine-display-positive-color': _ctx.$colors[$options.routine.settings?.colors?.positive || 'neutral'][500],
      '--ps-routine-display-positive-color-hover': _ctx.$colors[$options.routine.settings?.colors?.positive || 'neutral'][600],
      '--ps-routine-display-negative-color': _ctx.$colors[$options.routine.settings?.colors?.negative || 'neutral'][500],
      '--ps-routine-display-negative-color-hover': _ctx.$colors[$options.routine.settings?.colors?.negative || 'neutral'][600]
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.slots, (slot, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "group/routine_log relative group-hover/routine:!opacity-100"
    }, [_createElementVNode("a", {
      onClick: _withModifiers(slot.fn, ["stop"]),
      class: _normalizeClass({
        [_ctx.$style.routineSlot]: true,
        [_ctx.$style.routineSlotActive]: index == $options.slots.length - 1,
        [_ctx.$style.routineSlotTrue]: slot?.log?.completed !== null && slot?.log?.completed,
        [_ctx.$style.routineSlotFalse]: typeof slot.log !== 'undefined' && slot.log.completed === 0,
        [_ctx.$style['routineSlot' + $options.routine.frequency]]: true
      }),
      style: _normalizeStyle({
        opacity: _ctx.$route.params.id != $props.entry.id ? (index + 1) / $options.slots.length : null
      })
    }, [typeof slot.log == 'undefined' ? (_openBlock(), _createElementBlock("span", _hoisted_4, [index == $options.slots.length - 1 && $options.routine.value > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($options.routine.value), 1)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_6, _toDisplayString($options.getTitle(slot.date)), 1)])) : slot.log.completed === null ? (_openBlock(), _createElementBlock("span", _hoisted_7, [index == $options.slots.length - 1 && $options.routine.value > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($options.routine.value), 1)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_9, _toDisplayString($options.getTitle(slot.date)), 1)])) : slot?.log?.completed ? (_openBlock(), _createElementBlock("span", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString($options.getTitle(slot.date)), 1)])) : (_openBlock(), _createElementBlock("span", _hoisted_12, [_createElementVNode("span", _hoisted_13, _toDisplayString($options.getTitle(slot.date)), 1)]))], 14, _hoisted_3), _createElementVNode("span", _hoisted_14, [_createElementVNode("span", _hoisted_15, [_createTextVNode(_toDisplayString(slot.date) + " ", 1), typeof slot?.log == 'undefined' || slot?.log?.completed === null ? (_openBlock(), _createElementBlock("span", _hoisted_16, [_createVNode(_component_CircleHelpIcon, {
      size: "24"
    }), _cache[2] || (_cache[2] = _createTextVNode(" Skipped "))])) : slot?.log?.completed ? (_openBlock(), _createElementBlock("span", _hoisted_17, [_createVNode(_component_CircleCheckIcon, {
      size: "24"
    }), _cache[3] || (_cache[3] = _createTextVNode(" Completed "))])) : (_openBlock(), _createElementBlock("span", _hoisted_18, [_createVNode(_component_XCircleIcon, {
      size: "24"
    }), _cache[4] || (_cache[4] = _createTextVNode(" Uncompleted "))]))])])]);
  }), 128))], 12, _hoisted_2), $props.internalSettings.display.settings ? (_openBlock(), _createBlock(_component_RoutineSettings, {
    modelValue: $options.routine,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.routine = $event),
    key: "routine",
    entry: $props.entry
  }, {
    button_routine_configure: _withCtx(() => [_createVNode(_component_ButtonComponentNew, {
      title: "Configure Routine",
      class: _normalizeClass([_ctx.$style.routineSettingsButton]),
      "data-table-column-target": ""
    }, {
      default: _withCtx(() => [_createVNode(_component_SettingsIcon, {
        size: "20"
      })]),
      _: 1
    }, 8, ["class"])]),
    _: 1
  }, 8, ["modelValue", "entry"])) : _createCommentVNode("", true)])) : (_openBlock(), _createElementBlock("button", {
    key: 1,
    type: "button",
    onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => $options.createRoutine && $options.createRoutine(...args), ["stop"])),
    "data-table-column-target-disabled": ""
  }))], 2);
}